/*eslint-disable*/
import React, { useEffect, useState, useRef } from "react";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer";
import gallery3 from "assets/img/gallery/gallery3.webp"


export default function Projecthighlight() {


  return (
    <>
      <IndexNavbar fixed />

      <section className="h-screen">
        <div className="xl:h-screen lg:h-screen md:h-screen h-screen sm:h-auto min-h-screen flex justify-center items-center"
          >
          <iframe src="https://futeservices.in/24-25/IndiaBulls/V2/index.html" style={{border: "0px", width:"100%", height:"100%"}}></iframe>

        </div>
      </section>


      <div id="ds-sticky-button" className="font xl:text-xl text-white ">
        <a href="/disclaimer" className="rounded">Disclaimer</a>
      </div>
      <Footer className="z-50"/>
    </>
  );
}
