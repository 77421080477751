/*eslint-disable*/
import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import unitatwod from "assets/img/typical/unitatwod.jpg";
import IndexNavbarr from "components/Navbars/IndexNavbarr.js";
import "./ImageMapComponent.css";
import Footer from "components/Footers/Footer";
import { useParams } from "react-router";
import unit1Typical from "assets/img/typical-2d/Tower B Typical Plan Unit 1 3BHK.jpg";
import unit2Typical from "assets/img/typical-2d/Tower B Typical Plan Unit 2 3BHK.jpg";
import unit3Typical from "assets/img/typical-2d/Tower B Typical Plan Unit 3 2BHK.jpg";
import unit4Typical from "assets/img/typical-2d/Tower B Typical Plan Unit 4 3BHK.jpg";
import unit1Refugee from "assets/img/refugee-2d/Tower B Refuge Floor Unit 1 2BHK.jpg";
import unit2Refugee from "assets/img/refugee-2d/Tower B Refuge Floor Unit 2 2BHK.jpg";

export default function Unitb2d() {
  const history = useHistory();

  const [activeArea, setActiveArea] = useState(null);
  const [activeAreaName, setActiveAreaName] = useState("");
  const [activeElement, setActiveElement] = useState(null);
  const [imageSize, setImageSize] = useState({
    width: 0,
    height: 0,
    top: 0,
    left: 0,
  });
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const imgRef = useRef(null);
  const originalWidth = 886;
  const { floor, plan, unit } = useParams();
  const paddingX = 5;
  const paddingY = 5;

  const resizeImageMap = () => {
    const img = imgRef.current;
    const map = document.querySelector('map[name="image-map"]');
    const widthRatio = img.clientWidth / originalWidth;

    map.querySelectorAll("area").forEach((area) => {
      const originalCoords = area.dataset.coords.split(",").map(Number);
      const newCoords = originalCoords.map((coord) => coord * widthRatio);
      area.coords = newCoords.join(",");
    });
  };

  useEffect(() => {
    setIsTouchDevice("ontouchstart" in window || navigator.maxTouchPoints > 0);
    setActiveArea(null);
    resizeImageMap();

    window.addEventListener("resize", resizeImageMap);
    window.addEventListener("load", resizeImageMap);

    const img = document.querySelector("img[usemap]");
    if (img) {
      const updateImageSize = () => {
        const { clientWidth, clientHeight, offsetTop, offsetLeft } = img;
        setImageSize({
          width: clientWidth,
          height: clientHeight,
          top: offsetTop,
          left: offsetLeft,
        });
      };
      updateImageSize();
      window.addEventListener("load", updateImageSize);
      window.addEventListener("resize", updateImageSize);

      return () => window.removeEventListener("resize", updateImageSize);
    }

    return () => {
      window.removeEventListener("resize", resizeImageMap);
      window.removeEventListener("load", resizeImageMap);
    };
  }, []);

  useEffect(() => {
    console.log(activeAreaName);
  }, [activeAreaName]);

  const handleAreaClick = (area) => {
    setActiveArea(area.target.coords.split(",").map(Number));
    setActiveElement(area.target.id);
    setActiveAreaName(area.target.getAttribute("data-title"));
  };

  const handleAreaMouseEnter = (area) => {
    if (!isTouchDevice) {
      setActiveArea(area.target.coords.split(",").map(Number));
      setActiveElement(area.target.id);
      setActiveAreaName(area.target.getAttribute("data-title"));
    }
  };

  const handleAreaMouseLeave = (area) => {
    if (!isTouchDevice) {
      setActiveArea(null);
      setActiveElement(null);
      setActiveAreaName("");
    }
  };

  return (
    <>
      <IndexNavbarr fixed />

      <section className="relative h-screen xl:py-32 lg:pt-32 md:pt-24 xl:pb-12 pt-24 bg-gray-100">
        <div className="container mx-auto flex justify-center items-center">
          <div className="flex flex-wrap justify-between  lg:flex-row flex-col-reverse">
            <div className="w-full xl:w-2/12 lg:w-2/12 md:w-12/12 w-full  px-1 pt-2 ">
              <div
                className="bg-white  rounded-lg  "
                style={{
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                  height: "500px",
                  overflowY: "scroll",
                }}
              >
                {plan === "typical" && unit === "1" && (
                  <table className=" rounded-lg w-full py-2">
                    <tr
                      className="t_foyer"
                      style={
                        ["mainImage_1_9"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Entrance Foyer
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        4'6" x 6'3"
                      </td>
                    </tr>
                    <tr
                      className="t_living "
                      style={
                        ["mainImage_1_13"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Living/Dining{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'4" x 19'10" <br></br>& 3'8" x 12'2"
                      </td>
                    </tr>
                    <tr
                      className="t_balcony_1"
                      style={
                        ["mainImage_1_12"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Deck
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 4'7"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_1_8"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Kitchen
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'10" x 8'
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_1_7"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Dry Balcony
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'3" x 8'0"
                      </td>
                    </tr>
                    <tr
                      className="t_utility"
                      style={
                        ["mainImage_1_6"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Utility/Storage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        7' x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_1"
                      style={
                        ["mainImage_1_14"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Pooja Room
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        1'2" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_balcony"
                      style={
                        ["mainImage_1_18"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Bedroom 2 Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'5" x 3'7"
                      </td>
                    </tr>
                    <tr
                      className="t_passage"
                      style={
                        ["mainImage_1_17"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2 Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        2'10" × 1'18"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_2"
                      style={
                        ["mainImage_1_15"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'7"× 11'
                      </td>
                    </tr>
                    <tr
                      className="t_toilet_2"
                      style={
                        ["mainImage_1_16"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Toilet 2{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        8' x 5'
                      </td>
                    </tr>
                    <tr
                      className="t_master_passage"
                      style={
                        ["mainImage_1_11"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Passage 1
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5'× 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_bedroom"
                      style={
                        ["mainImage_1_10"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Bedroom 3
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_1_5"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Common Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        8' x 4'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_1_2"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'5" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_1_1"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Bedroom
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'10"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_1_3"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 2'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_1_4"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'4"
                      </td>
                    </tr>
                  </table>
                )}
                {plan === "typical" && unit === "2" && (
                  <table className=" rounded-lg w-full py-2">
                    <tr
                      className="t_foyer"
                      style={
                        ["mainImage_2_6"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Entrance Foyer
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        4'6" x 6'3"
                      </td>
                    </tr>
                    <tr
                      className="t_living "
                      style={
                        ["mainImage_2_10"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Living/Dining{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'4" x 19'10" <br></br>& 3'8" x 12'2"
                      </td>
                    </tr>
                    <tr
                      className="t_balcony_1"
                      style={
                        ["mainImage_2_5"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Deck
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 4'7"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_2_7"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Kitchen
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'10" x 8'
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_2_8"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Dry Balcony
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'3" x 8'0"
                      </td>
                    </tr>
                    <tr
                      className="t_utility"
                      style={
                        ["mainImage_2_9"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Utility/Storage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        7' x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_1"
                      style={
                        ["mainImage_2_11"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Pooja Room
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        1'2" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_balcony"
                      style={
                        ["mainImage_2_4"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Bedroom 2 Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'5" x 3'7"
                      </td>
                    </tr>
                    <tr
                      className="t_passage"
                      style={
                        ["mainImage_2_3"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2 Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        2'10" × 1'18"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_2"
                      style={
                        ["mainImage_2_1"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'7"× 11'
                      </td>
                    </tr>
                    <tr
                      className="t_toilet_2"
                      style={
                        ["mainImage_2_2"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Toilet 2{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        8' x 5'
                      </td>
                    </tr>
                    <tr
                      className="t_master_passage"
                      style={
                        ["mainImage_2_14"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Passage 1
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5'× 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_bedroom"
                      style={
                        ["mainImage_2_13"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Bedroom 3
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_2_12"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Common Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        8' x 4'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_2_18"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'5" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_2_15"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Bedroom
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'10"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_2_16"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 2'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_2_17"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'4"
                      </td>
                    </tr>
                  </table>
                )}
                {plan === "typical" && unit === "3" && (
                  <table className=" rounded-lg w-full py-2">
                    <tr
                      className="t_living "
                      style={
                        ["mainImage_3_10"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Living/Dining{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 19'4" <br></br>& 6'3" x 11'8"
                      </td>
                    </tr>
                    <tr
                      className="t_balcony_1"
                      style={
                        ["mainImage_3_9"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Deck
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 4'7"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_3_7"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Kitchen
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'6" x 8'4"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_3_6"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Dry Balcony
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'3" x 7'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_balcony"
                      style={
                        ["mainImage_3_5"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_2"
                      style={
                        ["mainImage_3_8"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'× 12'
                      </td>
                    </tr>
                    <tr
                      className="t_toilet_2"
                      style={
                        ["mainImage_3_4"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Common Toilet{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_3_1"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Bedroom
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_3_3"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        6'3" x 2'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_3_2"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'
                      </td>
                    </tr>
                  </table>
                )}
                {plan === "typical" && unit === "4" && (
                  <table className=" rounded-lg w-full py-2">
                    <tr
                      className="t_living "
                      style={
                        ["mainImage_4_11"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Living/Dining{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 19'14" <br></br>& 5'5" x 11'10"
                      </td>
                    </tr>
                    <tr
                      className="t_balcony_1"
                      style={
                        ["mainImage_4_10"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Deck
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 4'7"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_4_9"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Kitchen
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'6" x 8'4"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_4_8"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Dry Balcony
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'3" x 8'4"
                      </td>
                    </tr>
                    <tr
                      className="t_master_balcony"
                      style={
                        ["mainImage_4_3"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        12'2" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_passage"
                      style={
                        ["mainImage_4_6"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 3
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'8" × 12'10"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_2"
                      style={
                        ["mainImage_4_7"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Common Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5'× 8'
                      </td>
                    </tr>
                    <tr
                      className="t_toilet_2"
                      style={
                        ["mainImage_4_5"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Bedroom 2{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'10" x 12'10"
                      </td>
                    </tr>
                    <tr
                      className="t_master_passage"
                      style={
                        ["mainImage_4_4"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Toilet 2
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5'× 7'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_bedroom"
                      style={
                        ["mainImage_4_1"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Master Bedroom
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        12'6" x 11'10"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_4_2"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        8'8" x 5'
                      </td>
                    </tr>
                  </table>
                )}
                {plan === "second" && unit === "1" && (
                  <table className=" rounded-lg w-full py-2">
                    <tr
                      className="t_foyer"
                      style={
                        ["mainImage_1_9"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Entrance Foyer
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        4'6" x 6'3"
                      </td>
                    </tr>
                    <tr
                      className="t_living "
                      style={
                        ["mainImage_1_13"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Living/Dining{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'4" x 19'10" <br></br>& 3'8" x 12'2"
                      </td>
                    </tr>
                    <tr
                      className="t_balcony_1"
                      style={
                        ["mainImage_1_12"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Deck
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 4'7"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_1_8"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Kitchen
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'10" x 8'
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_1_7"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Dry Balcony
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'3" x 8'0"
                      </td>
                    </tr>
                    <tr
                      className="t_utility"
                      style={
                        ["mainImage_1_6"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Utility/Storage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        7' x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_1"
                      style={
                        ["mainImage_1_14"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Pooja Room
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        1'2" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_balcony"
                      style={
                        ["mainImage_1_18"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Bedroom 2 Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'5" x 3'7"
                      </td>
                    </tr>
                    <tr
                      className="t_passage"
                      style={
                        ["mainImage_1_17"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2 Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        2'10" × 1'18"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_2"
                      style={
                        ["mainImage_1_15"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'7"× 11'
                      </td>
                    </tr>
                    <tr
                      className="t_toilet_2"
                      style={
                        ["mainImage_1_16"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Toilet 2{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        8' x 5'
                      </td>
                    </tr>
                    <tr
                      className="t_master_passage"
                      style={
                        ["mainImage_1_11"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Passage 1
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5'× 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_bedroom"
                      style={
                        ["mainImage_1_10"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Bedroom 3
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_1_5"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Common Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        8' x 4'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_1_2"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'5" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_1_1"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Bedroom
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'10"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_1_3"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 2'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_1_4"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'4"
                      </td>
                    </tr>
                  </table>
                )}
                {plan === "second" && unit === "2" && (
                  <table className=" rounded-lg w-full py-2">
                    <tr
                      className="t_foyer"
                      style={
                        ["mainImage_2_6"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Entrance Foyer
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        4'6" x 6'3"
                      </td>
                    </tr>
                    <tr
                      className="t_living "
                      style={
                        ["mainImage_2_10"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Living/Dining{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'4" x 19'10" <br></br>& 3'8" x 12'2"
                      </td>
                    </tr>
                    <tr
                      className="t_balcony_1"
                      style={
                        ["mainImage_2_5"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Deck
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 4'7"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_2_7"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Kitchen
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'10" x 8'
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_2_8"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Dry Balcony
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'3" x 8'0"
                      </td>
                    </tr>
                    <tr
                      className="t_utility"
                      style={
                        ["mainImage_2_9"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Utility/Storage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        7' x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_1"
                      style={
                        ["mainImage_2_11"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Pooja Room
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        1'2" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_balcony"
                      style={
                        ["mainImage_2_4"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Bedroom 2 Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'5" x 3'7"
                      </td>
                    </tr>
                    <tr
                      className="t_passage"
                      style={
                        ["mainImage_2_3"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2 Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        2'10" × 1'18"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_2"
                      style={
                        ["mainImage_2_1"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'7"× 11'
                      </td>
                    </tr>
                    <tr
                      className="t_toilet_2"
                      style={
                        ["mainImage_2_2"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Toilet 2{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        8' x 5'
                      </td>
                    </tr>
                    <tr
                      className="t_master_passage"
                      style={
                        ["mainImage_2_14"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Passage 1
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5'× 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_bedroom"
                      style={
                        ["mainImage_2_13"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Bedroom 3
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_2_12"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Common Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        8' x 4'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_2_18"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'5" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_2_15"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Bedroom
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'10"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_2_16"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 2'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_2_17"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'4"
                      </td>
                    </tr>
                  </table>
                )}
                {plan === "second" && unit === "3" && (
                  <table className=" rounded-lg w-full py-2">
                    <tr
                      className="t_living "
                      style={
                        ["mainImage_3_10"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Living/Dining{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 19'4" <br></br>& 6'3" x 11'8"
                      </td>
                    </tr>
                    <tr
                      className="t_balcony_1"
                      style={
                        ["mainImage_3_9"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Deck
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 4'7"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_3_7"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Kitchen
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'6" x 8'4"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_3_6"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Dry Balcony
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'3" x 7'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_balcony"
                      style={
                        ["mainImage_3_5"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_2"
                      style={
                        ["mainImage_3_8"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'× 12'
                      </td>
                    </tr>
                    <tr
                      className="t_toilet_2"
                      style={
                        ["mainImage_3_4"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Common Toilet{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_3_1"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Bedroom
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_3_3"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        6'3" x 2'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_3_2"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'
                      </td>
                    </tr>
                  </table>
                )}

                {plan === "refugee" && unit === "1" && (
                  <table className=" rounded-lg w-full py-2">
                    <tr
                      className="t_living "
                      style={
                        ["s_mainImage_1_9"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Entrance Foyer{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        4'6" x 6'3"
                      </td>
                    </tr>
                    <tr
                      className="t_living "
                      style={
                        ["s_mainImage_1_14"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Living/Dining{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'4" x 19'10"{" "}
                      </td>
                    </tr>
                    <tr
                      className="t_balcony_1"
                      style={
                        ["s_mainImage_1_13"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Deck
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 4'7"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["s_mainImage_1_8"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Study Room
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        6'8" x 8'4"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["s_mainImage_1_10"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Kitchen
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'10" x 8'
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["s_mainImage_1_7"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Dry Balcony
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'3" x 8'0"
                      </td>
                    </tr>
                    <tr
                      className="t_master_balcony"
                      style={
                        ["s_mainImage_1_16"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'0" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_2"
                      style={
                        ["s_mainImage_1_15"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'× 12'
                      </td>
                    </tr>
                    <tr
                      className="t_toilet_2"
                      style={
                        ["s_mainImage_1_5"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Common Toilet{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5'0" x 8'4"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["s_mainImage_1_2"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Bedroom Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        2'4" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["s_mainImage_1_1"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Bedroom
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'10"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["s_mainImage_1_3"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 2'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["s_mainImage_1_4"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'4"
                      </td>
                    </tr>
                  </table>
                )}
                {plan === "refugee" && unit === "2" && (
                  <table className=" rounded-lg w-full py-2">
                    <tr
                      className="t_living "
                      style={
                        ["s_mainImage_2_6"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Entrance Foyer{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        4'6" x 6'3"
                      </td>
                    </tr>
                    <tr
                      className="t_living "
                      style={
                        ["s_mainImage_2_17"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Living/Dining{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'4" x 19'10"{" "}
                      </td>
                    </tr>
                    <tr
                      className="t_balcony_1"
                      style={
                        ["s_mainImage_2_16"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Deck
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 4'7"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["s_mainImage_2_15"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Study Room
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        6'8" x 8'4"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["s_mainImage_2_10"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Kitchen
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'10" x 8'
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["s_mainImage_2_9"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Dry Balcony
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'3" x 8'0"
                      </td>
                    </tr>
                    <tr
                      className="t_master_balcony"
                      style={
                        ["s_mainImage_2_11"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'0" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_2"
                      style={
                        ["s_mainImage_2_12"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'× 12'
                      </td>
                    </tr>
                    <tr
                      className="t_toilet_2"
                      style={
                        ["s_mainImage_2_5"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Common Toilet{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5'0" x 8'4"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["s_mainImage_2_2"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Bedroom Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        2'4" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["s_mainImage_2_1"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Bedroom
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'10"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["s_mainImage_2_4"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 2'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["s_mainImage_2_3"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'4"
                      </td>
                    </tr>
                  </table>
                )}

                {plan === "refugee" && unit === "3" && (
                  <table className=" rounded-lg w-full py-2">
                    <tr
                      className="t_living "
                      style={
                        ["mainImage_3_10"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Living/Dining{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 19'4" <br></br>& 6'3" x 11'8"
                      </td>
                    </tr>
                    <tr
                      className="t_balcony_1"
                      style={
                        ["mainImage_3_9"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Deck
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 4'7"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_3_7"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Kitchen
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'6" x 8'4"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_3_6"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Dry Balcony
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'3" x 7'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_balcony"
                      style={
                        ["mainImage_3_5"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_2"
                      style={
                        ["mainImage_3_8"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'× 12'
                      </td>
                    </tr>
                    <tr
                      className="t_toilet_2"
                      style={
                        ["mainImage_3_4"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Common Toilet{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_3_1"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Bedroom
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_3_3"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        6'3" x 2'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_3_2"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'
                      </td>
                    </tr>
                  </table>
                )}
                {plan === "refugee" && unit === "4" && (
                  <table className=" rounded-lg w-full py-2">
                    <tr
                      className="t_living "
                      style={
                        ["mainImage_4_11"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Living/Dining{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 19'14" <br></br>& 5'5" x 11'10"
                      </td>
                    </tr>
                    <tr
                      className="t_balcony_1"
                      style={
                        ["mainImage_4_10"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Deck
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 4'7"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_4_9"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Kitchen
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'6" x 8'4"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_4_8"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Dry Balcony
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'3" x 8'4"
                      </td>
                    </tr>
                    <tr
                      className="t_master_balcony"
                      style={
                        ["mainImage_4_3"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        12'2" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_passage"
                      style={
                        ["mainImage_4_6"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 3
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'8" × 12'10"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_2"
                      style={
                        ["mainImage_4_7"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Common Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5'× 8'
                      </td>
                    </tr>
                    <tr
                      className="t_toilet_2"
                      style={
                        ["mainImage_4_5"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Bedroom 2{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'10" x 12'10"
                      </td>
                    </tr>
                    <tr
                      className="t_master_passage"
                      style={
                        ["mainImage_4_4"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Toilet 2
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5'× 7'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_bedroom"
                      style={
                        ["mainImage_4_1"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Master Bedroom
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        12'6" x 11'10"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_4_2"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        8'8" x 5'
                      </td>
                    </tr>
                  </table>
                )}
              </div>
              <div className="px-2 pt-12 pb-16 xl:hidden lg:hidden block">
                <h1
                  className="font-bold uppercase text-sm font"
                  style={{ color: "#be935f" }}
                >
                  Apartment Features
                </h1>
                <p className="xl:pt-4 pt-2 font font-semibold text-sm">
                  1. Homes have wide sundecks
                </p>
                <p className="xl:pt-2 pt-2 font font-semibold text-sm">
                  2. Internal Flooring
                </p>
                <p className=" font text-sm">
                  Large format Vitrified tiles, anti-skid Vitrified tiles in
                  balcony and utility room
                </p>
                <p className="xl:pt-2 pt-2 font font-semibold text-sm">
                  3. Windows
                </p>
                <p className=" font text-sm">
                  Heavy Duty aluminium Single-glazed windows
                </p>
                <p className="xl:pt-2 pt-2 font font-semibold text-sm">
                  4. Doors
                </p>
                <p className=" font text-sm">
                  Main entrance door in veneer with melamine finish & laminate
                  on all internal doors
                </p>
              </div>
            </div>

            <div className="w-full xl:w-8/12 lg:w-8/12 md:w-12/12 px-6 xl:pt-0 lg:pt-0 md:pt-0 pt-6 pb-2">
              {plan === "typical" && (
                <>
                  {unit === "1" && (
                    <>
                      <img
                        src={unit1Typical}
                        useMap="#image-map"
                        ref={imgRef}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "100%",
                        }}
                      />
                      <map name="image-map">
                        <area
                          target=""
                          alt="master bedroom"
                          data-title={"Master Bedroom\n 11' x 12'10\""}
                          href=""
                          data-coords="657,246,656,102,671,102,675,96,768,97,768,103,784,103,784,245"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_1"
                        />
                        <area
                          target=""
                          alt="master passage"
                          data-title={"Master Passage\n 3'5\" x 3'5\""}
                          href=""
                          data-coords="615,248,655,245,654,207,614,208"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_2"
                        />
                        <area
                          target=""
                          alt="master wardrobe"
                          data-title={"Master Wardrobe\n 5' x 2'"}
                          href=""
                          data-coords="662,271,721,271,719,247,664,247"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_3"
                        />
                        <area
                          target=""
                          alt="master toilet"
                          data-title={"Master Toilet\n 5' x 8'4\""}
                          href=""
                          data-coords="728,351,728,254,785,254,784,313,792,320,791,351"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_4"
                        />
                        <area
                          target=""
                          alt="common toilet"
                          data-title={"Common Toilet\n 8' x 4'5\""}
                          href=""
                          data-coords="554,304,648,304,649,280,639,275,639,254,554,254"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_5"
                        />
                        <area
                          target=""
                          alt="utility / storage"
                          data-title={"Utility/Storage\n 7' x 3'5\""}
                          href=""
                          data-coords="570,353,646,350,646,312,568,313"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_6"
                        />
                        <area
                          target=""
                          alt="dry balcony"
                          data-title={"Dry Balcony\n 3'3\" x 8'0\""}
                          href=""
                          data-coords="564,453,599,455,599,359,565,359"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_7"
                        />
                        <area
                          target=""
                          alt="kitchen"
                          data-title={"Kitchen\n 10'10\" x 8'"}
                          href=""
                          data-coords="428,448,555,447,556,356,428,355"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_8"
                        />
                        <area
                          target=""
                          alt="passage 2"
                          data-title={"Entrance Foyer\n 4'6\" x 6'3\""}
                          href=""
                          data-coords="371,425,422,426,422,354,371,354"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_9"
                        />
                        <area
                          target=""
                          alt="bedroom 3"
                          data-title={"Bedroom 3\n 11' x 12'"}
                          href=""
                          data-coords="509,200,639,201,640,58,511,59"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_10"
                        />
                        <area
                          target=""
                          alt="passage 1"
                          data-title={"Passage 1\n 5' x 3'5\""}
                          href=""
                          data-coords="550,247,550,208,610,207,610,247"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_11"
                        />
                        <area
                          target=""
                          alt="deck"
                          data-title={"Deck\n 11'2\" x 4'7\""}
                          href=""
                          data-coords="372,107,500,107,501,64,372,64"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_12"
                        />
                        <area
                          target=""
                          alt="living / dining"
                          data-title={
                            "Living / Dining\n 11'4\" x 19'10\" & 3'8\" x 12'2\""
                          }
                          href=""
                          data-coords="372,352,549,350,548,206,504,206,502,115,372,115"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_13"
                        />
                        <area
                          target=""
                          alt="pooja"
                          data-title={"Pooja Room\n 1'2\" x 3'5\""}
                          href=""
                          data-coords="550,351,550,311,562,309,563,350"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_14"
                        />
                        <area
                          target=""
                          alt="bedroom 2"
                          data-title={"Bedroom 2\n 11'7\" x 11'"}
                          href=""
                          data-coords="355,241,220,238,220,109,341,108,342,114,356,114"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_15"
                        />
                        <area
                          target=""
                          alt="bedroom 2 toilet"
                          data-title={"Toilet 2 \n 8' x 5'"}
                          href=""
                          data-coords="309,246,220,246,220,302,311,302"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_16"
                        />
                        <area
                          target=""
                          alt="bedroom 2 wardrobe"
                          data-title={"Bedroom 2 Wardrobe\n 2'10\" x 1'18\""}
                          href=""
                          data-coords="315,282,316,303,349,305,351,284"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_17"
                        />
                        <area
                          target=""
                          alt="bedroom 2 passage"
                          data-title={"Bedroom 2 Passage\n 3'5\" x 3'7\""}
                          href=""
                          data-coords="369,281,317,283,317,249,369,251"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_18"
                        />
                      </map>
                    </>
                  )}
                  {unit === "2" && (
                    <>
                      <img
                        src={unit2Typical}
                        useMap="#image-map"
                        ref={imgRef}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "100%",
                        }}
                      />
                      <map name="image-map">
                        <area
                          target=""
                          alt="bedroom 2"
                          data-title={"Bedroom 2\n 11'7\" x 11'"}
                          href=""
                          data-coords="674,237,815,233,815,98,688,100,688,105,674,105"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_1"
                        />
                        <area
                          target=""
                          alt="bedroom 2 toilet"
                          data-title={"Toilet 2 \n 8' x 5'"}
                          href=""
                          data-coords="720,241,720,299,816,299,815,242"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_2"
                        />
                        <area
                          target=""
                          alt="bedroom 2 wardrobe"
                          data-title={"Bedroom 2 Wardrobe\n 2'10\" x 1'18\""}
                          href=""
                          data-coords="679,281,716,278,715,301,680,299"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_3"
                        />
                        <area
                          target=""
                          alt="bedroom 2 passage"
                          data-title={"Bedroom 2 Passage\n 3'5\" x 3'7\""}
                          href=""
                          data-coords="658,242,713,242,716,279,658,279"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_4"
                        />
                        <area
                          target=""
                          alt="deck"
                          data-title={"Deck\n 11'2\" x 4'7\""}
                          href=""
                          data-coords="522,97,656,97,655,52,523,52"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_5"
                        />
                        <area
                          target=""
                          alt="passage 2"
                          data-title={"Entrance Foyer\n 4'6\" x 6'3\""}
                          href=""
                          data-coords="603,355,658,355,657,430,606,430"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_6"
                        />
                        <area
                          target=""
                          alt="kitchen"
                          data-title={"Kitchen\n 10'10\" x 8'"}
                          href=""
                          data-coords="464,357,597,356,596,453,465,454"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_7"
                        />
                        <area
                          target=""
                          alt="dry balcony"
                          data-title={"Dry Balcony\n 3'3\" x 8'0\""}
                          href=""
                          data-coords="455,360,421,360,420,457,457,457"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_8"
                        />
                        <area
                          target=""
                          alt="utility / storage"
                          data-title={"Utility/Storage\n 7' x 3'5\""}
                          href=""
                          data-coords="449,352,370,350,368,311,452,312"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_9"
                        />
                        <area
                          target=""
                          alt="living / dining"
                          data-title={
                            "Living / Dining\n 11'4\" x 19'10\" & 3'8\" x 12'2\""
                          }
                          href=""
                          data-coords="473,350,656,350,656,106,520,105,520,196,472,200"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_10"
                        />
                        <area
                          target=""
                          alt="pooja"
                          data-title={"Pooja Room\n 1'2\" x 3'5\""}
                          href=""
                          data-coords="458,350,472,350,471,310,457,309"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_11"
                        />
                        <area
                          target=""
                          alt="common toilet"
                          data-title={"Common Toilet\n 8' x 4'5\""}
                          href=""
                          data-coords="378,249,378,274,366,276,367,302,467,303,467,246"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_12"
                        />
                        <area
                          target=""
                          alt="bedroom 3"
                          data-title={"Bedroom 3\n 11' x 12'"}
                          href=""
                          data-coords="378,194,512,195,512,47,377,47"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_13"
                        />
                        <area
                          target=""
                          alt="passage 1"
                          data-title={"Passage 1\n 5' x 3'5\""}
                          href=""
                          data-coords="473,243,472,201,408,200,408,243"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_14"
                        />
                        <area
                          target=""
                          alt="master bedroom"
                          data-title={"Master Bedroom\n 11' x 12'10\""}
                          href=""
                          data-coords="360,240,225,241,226,89,240,93,244,84,341,84,341,92,360,92"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_15"
                        />
                        <area
                          target=""
                          alt="master wardrobe"
                          data-title={"Master Wardrobe\n 5' x 2'"}
                          href=""
                          data-coords="294,242,295,267,352,267,355,243"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_16"
                        />
                        <area
                          target=""
                          alt="master toilet"
                          data-title={"Master Toilet\n 5' x 8'4\""}
                          href=""
                          data-coords="287,350,216,350,216,316,224,318,225,247,284,250"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_17"
                        />
                        <area
                          target=""
                          alt="master passage"
                          data-title={"Master Passage\n 3'5\" x 3'5\""}
                          href=""
                          data-coords="405,203,361,200,361,242,404,243"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_18"
                        />
                      </map>
                    </>
                  )}
                  {unit === "3" && (
                    <>
                      <img
                        src={unit3Typical}
                        useMap="#image-map"
                        ref={imgRef}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "100%",
                        }}
                      />
                      <map name="image-map">
                        <area
                          target=""
                          alt="master bedroom"
                          data-title={"Master Bedroom\n 11' x 12'"}
                          href=""
                          data-coords="404,402,381,403,377,412,286,409,286,403,272,402,272,262,408,262"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_1"
                        />
                        <area
                          target=""
                          alt="master toilet"
                          data-title={"Master Toilet\n 5' x 8'"}
                          href=""
                          data-coords="320,257,260,254,261,158,320,159"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_2"
                        />
                        <area
                          target=""
                          alt="master wardrobe"
                          data-title={"Master Wardrobe\n 6'3\" x 2'"}
                          href=""
                          data-coords="403,260,328,261,328,237,404,237"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_3"
                        />
                        <area
                          target=""
                          alt="common toilet"
                          data-title={"Common Toilet\n 5' x 8'"}
                          href=""
                          data-coords="412,255,470,254,469,158,410,156"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_4"
                        />
                        <area
                          target=""
                          alt="master passage"
                          data-title={"Passage\n 5' x 3'5\""}
                          href=""
                          data-coords="476,260,410,262,410,301,475,301"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_5"
                        />
                        <area
                          target=""
                          alt="dry balcony"
                          data-title={"Dry Balcony\n 3'3\" x 7'5\""}
                          href=""
                          data-coords="401,152,368,153,368,51,400,52"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_6"
                        />
                        <area
                          target=""
                          alt="kitchen"
                          data-title={"Kitchen\n 10'6\"  x 8'4\""}
                          href=""
                          data-coords="479,151,474,143,412,144,411,48,538,48,538,151"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_7"
                        />
                        <area
                          target=""
                          alt="bedroom 2"
                          data-title={"Bedroom 2\n 10' x 12'"}
                          href=""
                          data-coords="412,447,425,447,426,455,522,456,522,449,534,447,534,306,412,305"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_8"
                        />
                        <area
                          target=""
                          alt="deck"
                          data-title={"Deck\n 11'2\" x 4'7\""}
                          href=""
                          data-coords="555,450,689,449,689,405,555,404"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_9"
                        />
                        <area
                          target=""
                          alt="living / dining"
                          data-title={
                            "Living/Dining \n 11'2\" x 19'4\" & 6'3\" x 11'8\""
                          }
                          href=""
                          data-coords="689,157,477,159,476,301,553,301,554,397,692,397"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_10"
                        />
                      </map>
                    </>
                  )}
                  {unit === "4" && (
                    <>
                      <img
                        src={unit4Typical}
                        useMap="#image-map"
                        ref={imgRef}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "100%",
                        }}
                      />
                      <map name="image-map">
                        <area
                          target=""
                          alt="master bedroom"
                          data-title={"Master Bedroom\n 12'6\" x 11'10\""}
                          href=""
                          data-coords="570,292,570,148,720,149,720,279,713,280,716,292"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_1"
                        />
                        <area
                          target=""
                          alt="master toilet"
                          data-title={"Master Toilet\n 8'8\" x 5'"}
                          href=""
                          data-coords="648,299,751,296,752,356,648,356"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_2"
                        />
                        <area
                          target=""
                          alt="master passage"
                          data-title={" Passage\n 12'2\" x 3'5\""}
                          href=""
                          data-coords="416,292,564,291,564,252,416,250"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_3"
                        />
                        <area
                          target=""
                          alt="bedroom 2 bathroom"
                          data-title={"Toilet 2\n 5' x 7'5\""}
                          href=""
                          data-coords="648,364,707,363,707,452,647,452"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_4"
                        />
                        <area
                          target=""
                          alt="bedroom 2"
                          data-title={" Bedroom 2 \n 10'10\" x 12'10\""}
                          href=""
                          data-coords="512,299,640,297,641,452,512,454"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_5"
                        />
                        <area
                          target=""
                          alt="bedroom 3"
                          data-title={" Bedroom 3 \n 10'8\" x 12'10\""}
                          href=""
                          data-coords="368,441,383,442,384,452,480,451,480,441,495,441,495,296,368,297"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_6"
                        />
                        <area
                          target=""
                          alt="common toilet"
                          data-title={"Common Toilet\n 5' x 8'"}
                          href=""
                          data-coords="421,151,480,150,480,247,421,247"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_7"
                        />
                        <area
                          target=""
                          alt="dry balcony"
                          data-title={"Dry Balcony \n 3'3\" x 8'4\""}
                          href=""
                          data-coords="512,142,545,143,546,48,512,47"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_8"
                        />
                        <area
                          target=""
                          alt="kitchen"
                          data-title={"Kitchen \n 10'6\" x 8'4\""}
                          href=""
                          data-coords="377,144,504,140,503,42,377,41"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_9"
                        />
                        <area
                          target=""
                          alt="deck"
                          data-title={"Deck\n 11'2\" x 4'7\""}
                          href=""
                          data-coords="216,434,349,434,350,391,216,390"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_10"
                        />
                        <area
                          target=""
                          alt="living / dining"
                          data-title={
                            "Living/Dining\n 11'2\" x 19'14\" & 5'5\" x 11'10\""
                          }
                          href=""
                          data-coords="216,380,350,382,347,292,415,290,414,145,216,152"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_11"
                        />
                      </map>
                    </>
                  )}
                </>
              )}
              {plan === "refugee" && (
                <>
                  {unit === "1" && (
                    <>
                      {/* Change Unit 1 Refugee Image here */}
                      <img
                        src={unit1Refugee}
                        useMap="#image-map"
                        ref={imgRef}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "100%",
                        }}
                      />
                      <map name="image-map">
                        <area
                          target=""
                          alt="master bedroom"
                          data-title={"Master Bedroom\n 11' x 12'10\""}
                          href=""
                          data-coords="596,241,742,239,742,201,728,198,728,90,713,89,712,81,613,81,613,89,594,90"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_1"
                        />
                        <area
                          target=""
                          alt="master passage"
                          data-title={"Master Bedroom Passage\n 2'4\" x 3'5\""}
                          href=""
                          data-coords="559,241,558,201,593,199,594,239,578,243"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_2"
                        />
                        <area
                          target=""
                          alt="master wardrobe"
                          data-title={"Master Wardrobe\n 5' x 2'"}
                          href=""
                          data-coords="600,243,662,243,662,264,600,265"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_3"
                        />
                        <area
                          target=""
                          alt="master toilet"
                          data-title={"Master Toilet\n 5' x 8'4\""}
                          href=""
                          data-coords="669,247,728,246,728,311,736,313,736,348,670,347"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_4"
                        />
                        <area
                          target=""
                          alt="common bathroom"
                          data-title={"Common Toilet\n 5'0\" x 8'4\""}
                          href=""
                          data-coords="520,247,578,246,577,274,588,273,590,300,583,301,586,347,520,348"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_5"
                        />
                        <area
                          target=""
                          alt="dry balcony"
                          data-title={"Dry Balcony\n 3'3\" x 8'0\""}
                          href=""
                          data-coords="500,454,535,454,533,357,499,358"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_7"
                        />
                        <area
                          target=""
                          alt="pooja room"
                          data-title={"Study Room\n 6'8\" x 8'4\""}
                          href=""
                          data-coords="437,347,513,347,512,246,437,247"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_8"
                        />
                        <area
                          target=""
                          alt="passage 1"
                          data-title={"Entrance foyer\n 4'6\" x 6'3\""}
                          href=""
                          data-coords="350,426,296,425,299,351,352,350"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_9"
                        />
                        <area
                          target=""
                          alt="kitchen"
                          data-title={"Kitchen\n 10'10\" x 8'"}
                          href=""
                          data-coords="489,357,359,356,359,449,491,449"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_10"
                        />
                        <area
                          target=""
                          alt="deck"
                          data-title={"Deck\n 11'2\" x 4'7\""}
                          href=""
                          data-coords="434,96,434,42,300,44,298,95"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_13"
                        />
                        <area
                          target=""
                          alt="living / dining"
                          data-title={"Living/Dining\n 11'4\" x 19'10\""}
                          href=""
                          data-coords="298,347,436,347,435,104,299,103"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_14"
                        />
                        <area
                          target=""
                          alt="bedroom 2"
                          data-title={"Bedroom 2\n 11' x 12'"}
                          href=""
                          data-coords="443,193,577,192,576,48,444,48"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_15"
                        />
                        <area
                          target=""
                          alt="bedroom 2 passage"
                          data-title={"Passage 2\n 10'0\" x 3'5\""}
                          href=""
                          data-coords="438,241,557,240,556,199,437,195"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_16"
                        />
                      </map>
                    </>
                  )}
                  {unit === "2" && (
                    <>
                      {/* Change Unit 2 Refugee Image here */}
                      <img
                        src={unit2Refugee}
                        useMap="#image-map"
                        ref={imgRef}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "100%",
                        }}
                      />
                      <map name="image-map">
                        <area
                          target=""
                          alt="master bedroom"
                          data-title={"Master Bedroom\n 11' x 12'10\""}
                          href=""
                          data-coords="251,241,253,90,269,91,272,83,370,83,369,90,387,90,388,241"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_1"
                        />
                        <area
                          target=""
                          alt="master passage"
                          data-title={"Master Bedroom Passage\n 2'4\" x 3'5\""}
                          href=""
                          data-coords="421,202,422,242,390,241,389,198"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_2"
                        />
                        <area
                          target=""
                          alt="master toilet"
                          data-title="master toilet"
                          href=""
                          data-coords="244,349,244,314,251,314,255,248,314,249,313,349"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_3"
                        />
                        <area
                          target=""
                          alt="master wardrobe"
                          data-title={"Master Wardrobe\n 5' x 2'"}
                          href=""
                          data-coords="320,265,321,241,383,242,383,266"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_4"
                        />
                        <area
                          target=""
                          alt="common bathroom"
                          data-title={"Common Toilet\n 5'0\" x 8'4\""}
                          href=""
                          data-coords="396,350,462,350,464,246,402,246,406,274,393,274"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_5"
                        />
                        <area
                          target=""
                          alt="passage 1"
                          data-title={"Entrance foyer\n 4'6\" x 6'3\""}
                          href=""
                          data-coords="635,428,685,428,686,350,631,350"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_6"
                        />
                        <area
                          target=""
                          alt="dry balcony"
                          data-title={"Dry Balcony\n 3'3\" x 8'0\""}
                          href=""
                          data-coords="484,458,446,458,449,360,483,358"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_9"
                        />
                        <area
                          target=""
                          alt="kitchen"
                          data-title={"Kitchen\n 10'10\" x 8'"}
                          href=""
                          data-coords="494,454,627,454,627,358,494,357"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_10"
                        />
                        <area
                          target=""
                          alt="passage 2"
                          data-title={"Passage\n 10'0\" x 3'5\""}
                          href=""
                          data-coords="546,241,428,241,428,201,546,201"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_11"
                        />
                        <area
                          target=""
                          alt="bedroom 2"
                          data-title={"Bedroom 2\n 11' x 12'"}
                          href=""
                          data-coords="406,193,539,192,540,44,405,43"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_12"
                        />
                        <area
                          target=""
                          alt="bedroom 2 passage"
                          data-title={"Study Room\n 6'8\" x 8'4\""}
                          href=""
                          data-coords="471,349,547,349,546,246,469,248"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_15"
                        />
                        <area
                          target=""
                          alt="deck"
                          data-title={"Deck\n 11'2\" x 4'7\""}
                          href=""
                          data-coords="550,94,685,95,685,43,549,44"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_16"
                        />
                        <area
                          target=""
                          alt="living / dining"
                          data-title={"Living/Dining\n 11'4\" x 19'10\""}
                          href=""
                          data-coords="688,349,685,106,547,104,548,350"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_17"
                        />
                      </map>
                    </>
                  )}
                  {unit === "3" && (
                    <>
                      <img
                        src={unit3Typical}
                        useMap="#image-map"
                        ref={imgRef}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "100%",
                        }}
                      />
                      <map name="image-map">
                        <area
                          target=""
                          alt="master bedroom"
                          data-title={"Master Bedroom\n 11' x 12'"}
                          href=""
                          data-coords="404,402,381,403,377,412,286,409,286,403,272,402,272,262,408,262"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_1"
                        />
                        <area
                          target=""
                          alt="master toilet"
                          data-title={"Master Toilet\n 5' x 8'"}
                          href=""
                          data-coords="320,257,260,254,261,158,320,159"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_2"
                        />
                        <area
                          target=""
                          alt="master wardrobe"
                          data-title={"Master Wardrobe\n 6'3\" x 2'"}
                          href=""
                          data-coords="403,260,328,261,328,237,404,237"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_3"
                        />
                        <area
                          target=""
                          alt="common toilet"
                          data-title={"Common Toilet\n 5' x 8'"}
                          href=""
                          data-coords="412,255,470,254,469,158,410,156"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_4"
                        />
                        <area
                          target=""
                          alt="master passage"
                          data-title={"Passage\n 5' x 3'5\""}
                          href=""
                          data-coords="476,260,410,262,410,301,475,301"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_5"
                        />
                        <area
                          target=""
                          alt="dry balcony"
                          data-title={"Dry Balcony\n 3'3\" x 7'5\""}
                          href=""
                          data-coords="401,152,368,153,368,51,400,52"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_6"
                        />
                        <area
                          target=""
                          alt="kitchen"
                          data-title={"Kitchen\n 10'6\"  x 8'4\""}
                          href=""
                          data-coords="479,151,474,143,412,144,411,48,538,48,538,151"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_7"
                        />
                        <area
                          target=""
                          alt="bedroom 2"
                          data-title={"Bedroom 2\n 10' x 12'"}
                          href=""
                          data-coords="412,447,425,447,426,455,522,456,522,449,534,447,534,306,412,305"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_8"
                        />
                        <area
                          target=""
                          alt="deck"
                          data-title={"Deck\n 11'2\" x 4'7\""}
                          href=""
                          data-coords="555,450,689,449,689,405,555,404"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_9"
                        />
                        <area
                          target=""
                          alt="living / dining"
                          data-title={
                            "Living/Dining \n 11'2\" x 19'4\" & 6'3\" x 11'8\""
                          }
                          href=""
                          data-coords="689,157,477,159,476,301,553,301,554,397,692,397"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_10"
                        />
                      </map>
                    </>
                  )}
                  {unit === "4" && (
                    <>
                      <img
                        src={unit4Typical}
                        useMap="#image-map"
                        ref={imgRef}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "100%",
                        }}
                      />
                      <map name="image-map">
                        <area
                          target=""
                          alt="master bedroom"
                          data-title={"Master Bedroom\n 12'6\" x 11'10\""}
                          href=""
                          data-coords="570,292,570,148,720,149,720,279,713,280,716,292"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_1"
                        />
                        <area
                          target=""
                          alt="master toilet"
                          data-title={"Master Toilet\n 8'8\" x 5'"}
                          href=""
                          data-coords="648,299,751,296,752,356,648,356"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_2"
                        />
                        <area
                          target=""
                          alt="master passage"
                          data-title={" Passage\n 12'2\" x 3'5\""}
                          href=""
                          data-coords="416,292,564,291,564,252,416,250"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_3"
                        />
                        <area
                          target=""
                          alt="bedroom 2 bathroom"
                          data-title={"Toilet 2\n 5' x 7'5\""}
                          href=""
                          data-coords="648,364,707,363,707,452,647,452"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_4"
                        />
                        <area
                          target=""
                          alt="bedroom 2"
                          data-title={" Bedroom 2 \n 10'10\" x 12'10\""}
                          href=""
                          data-coords="512,299,640,297,641,452,512,454"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_5"
                        />
                        <area
                          target=""
                          alt="bedroom 3"
                          data-title={" Bedroom 3 \n 10'8\" x 12'10\""}
                          href=""
                          data-coords="368,441,383,442,384,452,480,451,480,441,495,441,495,296,368,297"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_6"
                        />
                        <area
                          target=""
                          alt="common toilet"
                          data-title={"Common Toilet\n 5' x 8'"}
                          href=""
                          data-coords="421,151,480,150,480,247,421,247"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_7"
                        />
                        <area
                          target=""
                          alt="dry balcony"
                          data-title={"Dry Balcony \n 3'3\" x 8'4\""}
                          href=""
                          data-coords="512,142,545,143,546,48,512,47"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_8"
                        />
                        <area
                          target=""
                          alt="kitchen"
                          data-title={"Kitchen \n 10'6\" x 8'4\""}
                          href=""
                          data-coords="377,144,504,140,503,42,377,41"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_9"
                        />
                        <area
                          target=""
                          alt="deck"
                          data-title={"Deck\n 11'2\" x 4'7\""}
                          href=""
                          data-coords="216,434,349,434,350,391,216,390"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_10"
                        />
                        <area
                          target=""
                          alt="living / dining"
                          data-title={
                            "Living/Dining\n 11'2\" x 19'14\" & 5'5\" x 11'10\""
                          }
                          href=""
                          data-coords="216,380,350,382,347,292,415,290,414,145,216,152"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_11"
                        />
                      </map>
                    </>
                  )}
                </>
              )}
              {plan === "second" && (
                <>
                  {unit === "1" && (
                    <>
                      <img
                        src={unit1Typical}
                        useMap="#image-map"
                        ref={imgRef}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "100%",
                        }}
                      />
                      <map name="image-map">
                        <area
                          target=""
                          alt="master bedroom"
                          data-title={"Master Bedroom\n 11' x 12'10\""}
                          href=""
                          data-coords="657,246,656,102,671,102,675,96,768,97,768,103,784,103,784,245"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_1"
                        />
                        <area
                          target=""
                          alt="master passage"
                          data-title={"Master Passage\n 3'5\" x 3'5\""}
                          href=""
                          data-coords="615,248,655,245,654,207,614,208"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_2"
                        />
                        <area
                          target=""
                          alt="master wardrobe"
                          data-title={"Master Wardrobe\n 5' x 2'"}
                          href=""
                          data-coords="662,271,721,271,719,247,664,247"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_3"
                        />
                        <area
                          target=""
                          alt="master toilet"
                          data-title={"Master Toilet\n 5' x 8'4\""}
                          href=""
                          data-coords="728,351,728,254,785,254,784,313,792,320,791,351"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_4"
                        />
                        <area
                          target=""
                          alt="common toilet"
                          data-title={"Common Toilet\n 8' x 4'5\""}
                          href=""
                          data-coords="554,304,648,304,649,280,639,275,639,254,554,254"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_5"
                        />
                        <area
                          target=""
                          alt="utility / storage"
                          data-title={"Utility/Storage\n 7' x 3'5\""}
                          href=""
                          data-coords="570,353,646,350,646,312,568,313"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_6"
                        />
                        <area
                          target=""
                          alt="dry balcony"
                          data-title={"Dry Balcony\n 3'3\" x 8'0\""}
                          href=""
                          data-coords="564,453,599,455,599,359,565,359"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_7"
                        />
                        <area
                          target=""
                          alt="kitchen"
                          data-title={"Kitchen\n 10'10\" x 8'"}
                          href=""
                          data-coords="428,448,555,447,556,356,428,355"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_8"
                        />
                        <area
                          target=""
                          alt="passage 2"
                          data-title={"Entrance Foyer\n 4'6\" x 6'3\""}
                          href=""
                          data-coords="371,425,422,426,422,354,371,354"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_9"
                        />
                        <area
                          target=""
                          alt="bedroom 3"
                          data-title={"Bedroom 3\n 11' x 12'"}
                          href=""
                          data-coords="509,200,639,201,640,58,511,59"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_10"
                        />
                        <area
                          target=""
                          alt="passage 1"
                          data-title={"Passage 1\n 5' x 3'5\""}
                          href=""
                          data-coords="550,247,550,208,610,207,610,247"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_11"
                        />
                        <area
                          target=""
                          alt="deck"
                          data-title={"Deck\n 11'2\" x 4'7\""}
                          href=""
                          data-coords="372,107,500,107,501,64,372,64"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_12"
                        />
                        <area
                          target=""
                          alt="living / dining"
                          data-title={
                            "Living / Dining\n 11'4\" x 19'10\" & 3'8\" x 12'2\""
                          }
                          href=""
                          data-coords="372,352,549,350,548,206,504,206,502,115,372,115"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_13"
                        />
                        <area
                          target=""
                          alt="pooja"
                          data-title={"Pooja Room\n 1'2\" x 3'5\""}
                          href=""
                          data-coords="550,351,550,311,562,309,563,350"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_14"
                        />
                        <area
                          target=""
                          alt="bedroom 2"
                          data-title={"Bedroom 2\n 11'7\" x 11'"}
                          href=""
                          data-coords="355,241,220,238,220,109,341,108,342,114,356,114"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_15"
                        />
                        <area
                          target=""
                          alt="bedroom 2 toilet"
                          data-title={"Toilet 2 \n 8' x 5'"}
                          href=""
                          data-coords="309,246,220,246,220,302,311,302"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_16"
                        />
                        <area
                          target=""
                          alt="bedroom 2 wardrobe"
                          data-title={"Bedroom 2 Wardrobe\n 2'10\" x 1'18\""}
                          href=""
                          data-coords="315,282,316,303,349,305,351,284"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_17"
                        />
                        <area
                          target=""
                          alt="bedroom 2 passage"
                          data-title={"Bedroom 2 Passage\n 3'5\" x 3'7\""}
                          href=""
                          data-coords="369,281,317,283,317,249,369,251"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_18"
                        />
                      </map>
                    </>
                  )}
                  {unit === "2" && (
                    <>
                      <img
                        src={unit2Typical}
                        useMap="#image-map"
                        ref={imgRef}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "100%",
                        }}
                      />
                      <map name="image-map">
                        <area
                          target=""
                          alt="bedroom 2"
                          data-title={"Bedroom 2\n 11'7\" x 11'"}
                          href=""
                          data-coords="674,237,815,233,815,98,688,100,688,105,674,105"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_1"
                        />
                        <area
                          target=""
                          alt="bedroom 2 toilet"
                          data-title={"Toilet 2 \n 8' x 5'"}
                          href=""
                          data-coords="720,241,720,299,816,299,815,242"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_2"
                        />
                        <area
                          target=""
                          alt="bedroom 2 wardrobe"
                          data-title={"Bedroom 2 Wardrobe\n 2'10\" x 1'18\""}
                          href=""
                          data-coords="679,281,716,278,715,301,680,299"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_3"
                        />
                        <area
                          target=""
                          alt="bedroom 2 passage"
                          data-title={"Bedroom 2 Passage\n 3'5\" x 3'7\""}
                          href=""
                          data-coords="658,242,713,242,716,279,658,279"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_4"
                        />
                        <area
                          target=""
                          alt="deck"
                          data-title={"Deck\n 11'2\" x 4'7\""}
                          href=""
                          data-coords="522,97,656,97,655,52,523,52"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_5"
                        />
                        <area
                          target=""
                          alt="passage 2"
                          data-title={"Entrance Foyer\n 4'6\" x 6'3\""}
                          href=""
                          data-coords="603,355,658,355,657,430,606,430"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_6"
                        />
                        <area
                          target=""
                          alt="kitchen"
                          data-title={"Kitchen\n 10'10\" x 8'"}
                          href=""
                          data-coords="464,357,597,356,596,453,465,454"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_7"
                        />
                        <area
                          target=""
                          alt="dry balcony"
                          data-title={"Dry Balcony\n 3'3\" x 8'0\""}
                          href=""
                          data-coords="455,360,421,360,420,457,457,457"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_8"
                        />
                        <area
                          target=""
                          alt="utility / storage"
                          data-title={"Utility/Storage\n 7' x 3'5\""}
                          href=""
                          data-coords="449,352,370,350,368,311,452,312"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_9"
                        />
                        <area
                          target=""
                          alt="living / dining"
                          data-title={
                            "Living / Dining\n 11'4\" x 19'10\" & 3'8\" x 12'2\""
                          }
                          href=""
                          data-coords="473,350,656,350,656,106,520,105,520,196,472,200"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_10"
                        />
                        <area
                          target=""
                          alt="pooja"
                          data-title={"Pooja Room\n 1'2\" x 3'5\""}
                          href=""
                          data-coords="458,350,472,350,471,310,457,309"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_11"
                        />
                        <area
                          target=""
                          alt="common toilet"
                          data-title={"Common Toilet\n 8' x 4'5\""}
                          href=""
                          data-coords="378,249,378,274,366,276,367,302,467,303,467,246"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_12"
                        />
                        <area
                          target=""
                          alt="bedroom 3"
                          data-title={"Bedroom 3\n 11' x 12'"}
                          href=""
                          data-coords="378,194,512,195,512,47,377,47"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_13"
                        />
                        <area
                          target=""
                          alt="passage 1"
                          data-title={"Passage 1\n 5' x 3'5\""}
                          href=""
                          data-coords="473,243,472,201,408,200,408,243"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_14"
                        />
                        <area
                          target=""
                          alt="master bedroom"
                          data-title={"Master Bedroom\n 11' x 12'10\""}
                          href=""
                          data-coords="360,240,225,241,226,89,240,93,244,84,341,84,341,92,360,92"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_15"
                        />
                        <area
                          target=""
                          alt="master wardrobe"
                          data-title={"Master Wardrobe\n 5' x 2'"}
                          href=""
                          data-coords="294,242,295,267,352,267,355,243"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_16"
                        />
                        <area
                          target=""
                          alt="master toilet"
                          data-title={"Master Toilet\n 5' x 8'4\""}
                          href=""
                          data-coords="287,350,216,350,216,316,224,318,225,247,284,250"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_17"
                        />
                        <area
                          target=""
                          alt="master passage"
                          data-title={"Master Passage\n 3'5\" x 3'5\""}
                          href=""
                          data-coords="405,203,361,200,361,242,404,243"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_18"
                        />
                      </map>
                    </>
                  )}
                  {unit === "3" && (
                    <>
                      <img
                        src={unit3Typical}
                        useMap="#image-map"
                        ref={imgRef}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "100%",
                        }}
                      />
                      <map name="image-map">
                        <area
                          target=""
                          alt="master bedroom"
                          data-title={"Master Bedroom\n 11' x 12'"}
                          href=""
                          data-coords="404,402,381,403,377,412,286,409,286,403,272,402,272,262,408,262"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_1"
                        />
                        <area
                          target=""
                          alt="master toilet"
                          data-title={"Master Toilet\n 5' x 8'"}
                          href=""
                          data-coords="320,257,260,254,261,158,320,159"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_2"
                        />
                        <area
                          target=""
                          alt="master wardrobe"
                          data-title={"Master Wardrobe\n 6'3\" x 2'"}
                          href=""
                          data-coords="403,260,328,261,328,237,404,237"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_3"
                        />
                        <area
                          target=""
                          alt="common toilet"
                          data-title={"Common Toilet\n 5' x 8'"}
                          href=""
                          data-coords="412,255,470,254,469,158,410,156"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_4"
                        />
                        <area
                          target=""
                          alt="master passage"
                          data-title={"Passage\n 5' x 3'5\""}
                          href=""
                          data-coords="476,260,410,262,410,301,475,301"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_5"
                        />
                        <area
                          target=""
                          alt="dry balcony"
                          data-title={"Dry Balcony\n 3'3\" x 7'5\""}
                          href=""
                          data-coords="401,152,368,153,368,51,400,52"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_6"
                        />
                        <area
                          target=""
                          alt="kitchen"
                          data-title={"Kitchen\n 10'6\"  x 8'4\""}
                          href=""
                          data-coords="479,151,474,143,412,144,411,48,538,48,538,151"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_7"
                        />
                        <area
                          target=""
                          alt="bedroom 2"
                          data-title={"Bedroom 2\n 10' x 12'"}
                          href=""
                          data-coords="412,447,425,447,426,455,522,456,522,449,534,447,534,306,412,305"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_8"
                        />
                        <area
                          target=""
                          alt="deck"
                          data-title={"Deck\n 11'2\" x 4'7\""}
                          href=""
                          data-coords="555,450,689,449,689,405,555,404"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_9"
                        />
                        <area
                          target=""
                          alt="living / dining"
                          data-title={
                            "Living/Dining \n 11'2\" x 19'4\" & 6'3\" x 11'8\""
                          }
                          href=""
                          data-coords="689,157,477,159,476,301,553,301,554,397,692,397"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_10"
                        />
                      </map>
                    </>
                  )}
                </>
              )}
              {/* <img src={unitatwod} usemap="#image-map" ref={imgRef} style={{ maxWidth: '100%', height: 'auto', width: '100%' }} 
                            className="rounded-lg"/>
                            <map name="image-map">
                                <area target="" alt="" href="#!" data-coords="204,94,200,120,278,123,282,94" shape="poly" onClick={isTouchDevice ? handleAreaClick : undefined} onMouseLeave={!isTouchDevice ? handleAreaMouseLeave : undefined} onMouseEnter={!isTouchDevice ? handleAreaMouseEnter : undefined} id="mainImage_1_1" title="Master Balcony 7\'0&quot; &#10; x 4\'0&quot;" />
                                <area target="" alt="" href="#!" data-coords="148,237,176,123,299,124,288,238" shape="poly" onClick={isTouchDevice ? handleAreaClick : undefined} onMouseLeave={!isTouchDevice ? handleAreaMouseLeave : undefined} onMouseEnter={!isTouchDevice ? handleAreaMouseEnter : undefined} id="mainImage_1_2" title="Master Bedroom 11\'0&quot; &#10; x 15\'0&quot;" />
                                <area target="" alt="" href="#!" data-coords="231,287,344,288,346,241,242,241" shape="poly" onClick={isTouchDevice ? handleAreaClick : undefined} onMouseLeave={!isTouchDevice ? handleAreaMouseLeave : undefined} onMouseEnter={!isTouchDevice ? handleAreaMouseEnter : undefined} id="mainImage_1_3" title="Master Toilet 8\'0&quot; &#10; x 4\'6&quot;" />
                                <area target="" alt="" href="#!" data-coords="290,238,334,239,336,212,292,210" shape="poly" onClick={isTouchDevice ? handleAreaClick : undefined} onMouseLeave={!isTouchDevice ? handleAreaMouseLeave : undefined} onMouseEnter={!isTouchDevice ? handleAreaMouseEnter : undefined} id="mainImage_1_4" title="Master Bedroom Passage 3\'7&quot; &#10; x 3\'3&quot;" />
                                <area target="" alt="" href="#!" data-coords="336,238,416,239,416,209,336,210" shape="poly" onClick={isTouchDevice ? handleAreaClick : undefined} onMouseLeave={!isTouchDevice ? handleAreaMouseLeave : undefined} onMouseEnter={!isTouchDevice ? handleAreaMouseEnter : undefined} id="mainImage_1_5" title="Passage 6\'9&quot; &#10; x 3\'3&quot;" />
                                <area target="" alt="" href="#!" data-coords="292,208,416,208,415,120,303,118" shape="poly" onClick={isTouchDevice ? handleAreaClick : undefined} onMouseLeave={!isTouchDevice ? handleAreaMouseLeave : undefined} onMouseEnter={!isTouchDevice ? handleAreaMouseEnter : undefined} id="mainImage_1_6" title="Bedroom 2 10\'0&quot; &#10; x 12\'0&quot;" />
                                <area target="" alt="" href="#!" data-coords="342,316,412,317,408,240,349,240" shape="poly" onClick={isTouchDevice ? handleAreaClick : undefined} onMouseLeave={!isTouchDevice ? handleAreaMouseLeave : undefined} onMouseEnter={!isTouchDevice ? handleAreaMouseEnter : undefined} id="mainImage_1_7" title="Toilet 4\'6&quot; &#10; x 8\'0&quot;" />
                                <area target="" alt="" href="#!" data-coords="376,363,460,365,459,305,420,303,421,320,376,321" shape="poly" onClick={isTouchDevice ? handleAreaClick : undefined} onMouseLeave={!isTouchDevice ? handleAreaMouseLeave : undefined} onMouseEnter={!isTouchDevice ? handleAreaMouseEnter : undefined} id="mainImage_1_8" title="Utility 5\'11&quot; &#10; x 3\'10&quot;" />
                                <area target="" alt="" href="#!" data-coords="462,382,634,385,618,302,460,302" shape="poly" onClick={isTouchDevice ? handleAreaClick : undefined} onMouseLeave={!isTouchDevice ? handleAreaMouseLeave : undefined} onMouseEnter={!isTouchDevice ? handleAreaMouseEnter : undefined} id="mainImage_1_9" title="Kitchen 11\'4&quot; &#10; x 7\'6&quot;" />
                                <area target="" alt="" href="#!" data-coords="420,298,416,138,433,139,520,136,536,138,556,250,559,302" shape="poly" onClick={isTouchDevice ? handleAreaClick : undefined} onMouseLeave={!isTouchDevice ? handleAreaMouseLeave : undefined} onMouseEnter={!isTouchDevice ? handleAreaMouseEnter : undefined} id="mainImage_1_10" title="Living/Dining 11\'0&quot; &#10; x 20\'0&quot;" />
                                <area target="" alt="" href="#!" data-coords="607,252,556,251,560,301,617,303" shape="poly" onClick={isTouchDevice ? handleAreaClick : undefined} onMouseLeave={!isTouchDevice ? handleAreaMouseLeave : undefined} onMouseEnter={!isTouchDevice ? handleAreaMouseEnter : undefined} id="mainImage_1_11" title="Entrance Foyer 4\'0&quot; &#10; x 5\'3&quot;" />
                                <area target="" alt="" href="#!" data-coords="542,146,660,147,684,249,557,248" shape="poly" onClick={isTouchDevice ? handleAreaClick : undefined} onMouseLeave={!isTouchDevice ? handleAreaMouseLeave : undefined} onMouseEnter={!isTouchDevice ? handleAreaMouseEnter : undefined} id="mainImage_1_12" title=" Bedroom 1 10\'0&quot; &#10; x 13\'0&quot;" />
                                <area target="" alt="" href="#!" data-coords="431,102,436,137,522,134,516,102" shape="poly" onClick={isTouchDevice ? handleAreaClick : undefined} onMouseLeave={!isTouchDevice ? handleAreaMouseLeave : undefined} onMouseEnter={!isTouchDevice ? handleAreaMouseEnter : undefined} id="mainImage_1_13" title=" Balcony 7\'9&quot; &#10; x 5\'0&quot;" />
                                <area target="" alt="" href="#!" data-coords="682,232,745,235,722,166,666,166" shape="poly" onClick={isTouchDevice ? handleAreaClick : undefined} onMouseLeave={!isTouchDevice ? handleAreaMouseLeave : undefined} onMouseEnter={!isTouchDevice ? handleAreaMouseEnter : undefined} id="mainImage_1_14" title=" Toilet 4\'6&quot; &#10; x 8\'0&quot;" />
                            </map> */}
              {activeArea && (
                <svg
                  className="absolute pointer-events-none"
                  xmlns="http://www.w3.org/2000/svg"
                  width={imageSize.width}
                  height={imageSize.height}
                  style={{
                    top: `${imageSize.top}px`,
                    left: `${imageSize.left}px`,
                  }}
                >
                  <polygon
                    className="highlight"
                    points={activeArea.join(",")}
                  />

                  {(() => {
                    // Split text into multiple lines with maximum 15 characters per line
                    const lines = activeAreaName.split(" ").reduce(
                      (acc, word) => {
                        const lastLine = acc[acc.length - 1] || "";
                        if ((lastLine + " " + word).length <= 25) {
                          acc[acc.length - 1] = lastLine
                            ? `${lastLine} ${word}`
                            : word;
                        } else {
                          acc.push(word);
                        }
                        return acc;
                      },
                      [""]
                    );

                    // Calculate width and height for the rect based on longest line and number of lines
                    const maxLineWidth = Math.max(
                      ...lines.map((line) => line.length * 9)
                    );
                    const rectWidth = maxLineWidth + paddingX * 2;
                    const rectHeight = lines.length * 20 + paddingY * 2;

                    // X and Y coordinates for positioning the rect
                    const rectX =
                      Math.max(
                        ...activeArea.filter((_, index) => index % 2 === 0)
                      ) + 5;
                    const rectY =
                      Math.min(
                        ...activeArea.filter((_, index) => index % 2 !== 0)
                      ) -
                      25 -
                      paddingY;

                    return (
                      <>
                        {/* Background rect with calculated dimensions */}
                        <rect
                          x={rectX}
                          y={rectY}
                          width={rectWidth}
                          height={rectHeight}
                          fill="#d8b387"
                          rx="5"
                          ry="5"
                        />

                        {/* Render each line of text with calculated positioning */}
                        {lines.map((line, index) => (
                          <text
                            key={index}
                            x={rectX + paddingX}
                            y={rectY + paddingY + 16 + index * 16}
                            fill="white"
                            fontSize="14"
                            fontFamily="Outfit, sans-serif"
                            textAnchor="start"
                          >
                            {line}
                          </text>
                        ))}
                      </>
                    );
                  })()}
                </svg>
              )}
            </div>
            <div
              className="w-full xl:w-2/12  lg:w-2/12 md:w-12/12 w-full rounded-lg mt-6 xl:mt-0 lg:mt-0 "
              // style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}
            >
              <div
                className="flex xl:flex-wrap rounded-lg border lg:flex-wrap xl:gap-0 lg:gap-0 gap-4 items-center justify-between xl:py-6 py-6 xl:px-0 px-2"
                style={{
                  background: "#f0eeee",
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                }}
              >
                <div className="xl:pt-4 w-full xl:w-9/12 mx-auto">
                  <div
                    className="cursor-pointer font text-sm font-semibold text-center text-white py-2 rounded bgbutton"
                    onClick={() => history.goBack()}
                  >
                    Go Back
                  </div>
                </div>
                {/* <div className="xl:pt-2 lg:pt-2 w-full xl:w-9/12 mx-auto">
                                    <a href="/iso-3d">
                                        <div className="font text-sm font-semibold text-center text-white py-2 rounded bgbutton">
                                            3D Unit Plan
                                        </div>
                                    </a>
                                </div> */}
                <div className="xl:pt-2 pt-2 xl:block lg:block block w-full xl:w-9/12 mx-auto">
                  {/* <div className="font text-sm font-semibold text-center text-white py-2 rounded bgbutton">
                    Zoom Image
                  </div> */}
                </div>
              </div>

              {/* <div className="px-4 pb-4 xl:pt-4 xl:block lg:block hidden">
                                <h1 className="font-bold uppercase text-sm font" style={{color: "#be935f"}}>
                                    Apartment Features
                                </h1>
                                <p className="xl:pt-4 font font-semibold text-sm">
                                    1. Homes have wide sundecks
                                </p>
                                <p className="xl:pt-2 font font-semibold text-sm">
                                    2.  Internal Flooring
                                </p>
                                <p className=" font text-sm">
                                    Large format Vitrified tiles,
                                    anti-skid Vitrified tiles
                                    in balcony and utility room
                                </p>
                                <p className="xl:pt-2 font font-semibold text-sm">
                                    3. Windows
                                </p>
                                <p className=" font text-sm">
                                    Heavy Duty aluminium
                                    Single-glazed windows
                                </p>
                                <p className="xl:pt-2 font font-semibold text-sm">
                                    4. Doors
                                </p>
                                <p className=" font text-sm">
                                    Main entrance door in veneer with
                                    melamine finish & laminate
                                    on all internal doors
                                </p>
                            </div> */}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
