/*eslint-disable*/
import React, { useState } from "react";
import IndexNavbarr from "components/Navbars/IndexNavbarr.js";
import "./ImageMapComponent.css";
import Footer from "components/Footers/Footer";
import Clubhousepool from "assets/img/amenities/Clubhousepool.webp";
import Tenniscourt from "assets/img/amenities/Tenniscourt.webp";
import gymamenity from "assets/img/gymamenity.webp";
import Partyhall from "assets/img/amenities/Partyhall.webp";
import Seniorcitizen from "assets/img/amenities/Seniorcitizen.webp";
import store from "assets/img/amenities/store.webp";
import cricketpitch from "assets/img/amenities/cricketpitch.webp";
import yoga from "assets/img/amenities/yoga.webp";
import playarea from "assets/img/amenities/playarea.webp";
import Gymnasium from "assets/img/amenities/Gymnasium.webp";
import minitheater from "assets/img/amenities/minitheater.webp";
import cafe from "assets/img/amenities/Cafe.webp";
import gardens from "assets/img/amenities/gardens.webp";
import  multipurposelawn from "assets/img/amenities/multipurposelawn.webp"

export default function Amenities() {
  // const images = [gallery11, tennisamenity, embassy5, gymamenity, partyamenity, senioramenity, storeamenity, trackamenity, yogamenity,];
  const images =[Clubhousepool,  multipurposelawn, Tenniscourt, playarea, cricketpitch, gardens, Seniorcitizen, cafe, Gymnasium, Partyhall, store,  yoga, minitheater  ];
  //  embassy1, embassy2, embassy3, embassy4, embassy5, embassy6, embassy7, embassy8, embassy9, embassy10, embassy11,];
  const [currentIndex, setCurrentIndex] = useState(0); // State to track the current image

  // Function to handle next slide
  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    ); // Go to the next image, loop back to first if at the end
  };

  // Function to handle previous slide
  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    ); // Go to the previous image, loop back to last if at the start
  };

  return (
    <>
      <IndexNavbarr fixed />

      <section className="relative  xl:py-32 lg:pt-32 md:pt-16 xl:pb-32  pb-32 pt-24 bg-gray-100 min-h-screen flex justify-center items-center">
        <div className="container mx-auto flex justify-center items-center">
          <div className="flex flex-wrap justify-between lg:flex-row flex-col-reverse xl:px-0 lg:px-0 px-2 ">
            {/* Sidebar Section */}
            <div
              className="w-full xl:w-2/12  lg:w-2/12 md:w-12/12 w-full bg-white border rounded-lg mt-6 xl:mt-0 lg:mt-0 "
              style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}
            >
              <div
                className="flex xl:flex-wrap lg:flex-wrap items-center justify-between xl:py-6 py-6"
                style={{ background: "#f0eeee" }}
              >
                <div className="pt-4 w-full px-6">
                  <div className="font xxl:text-base xl:text-base lg:text-xs text-sm px-2 font-semibold text-center text-white py-2 rounded bgbutton">
                    Outdoor Amenities
                  </div>
                </div>
              </div>

              <ul className="list-disc list-inside px-4 pb-4 pt-2 xl:block lg:block ">
                <li className="xl:pt-2 pt-2 font xxl:text-sm xl:text-sm text-sm lg:text-xs">Swimming Pool</li>
                <li className="xl:pt-2 pt-2 font xxl:text-sm xl:text-sm text-sm lg:text-xs">Cafe</li>
                <li className="xl:pt-2 pt-2 font xxl:text-sm xl:text-sm text-sm lg:text-xs"> Children's Play Area</li>
                <li className="xl:pt-2 pt-2 font xxl:text-sm xl:text-sm text-sm lg:text-xs"> Tennis Court</li>
                <li className="xl:pt-2 pt-2 font xxl:text-sm xl:text-sm text-sm lg:text-xs">Cricket Pitch</li>
                <li className="xl:pt-2 pt-2 font xxl:text-sm xl:text-sm text-sm lg:text-xs">Senior Citizen Area</li>
                <li className="xl:pt-2 pt-2 font xxl:text-sm xl:text-sm text-sm lg:text-xs">Jogging Track </li>
                <li className="xl:pt-2 pt-2 font xxl:text-sm xl:text-sm text-sm lg:text-xs"> Multi-purpose Lawn</li>
              </ul>
            </div>

            {/* Image Slider Section */}
            <div className="w-full xl:w-8/12 lg:w-8/12 md:w-12/12 w-full  px-6 xl:pt-0 lg:pt-0 md:pt-0 pt-6 relative">
              <div className="relative flex items-center justify-center">
                {/* Previous arrow */}
                <button
                  className="modal-navigation1 absolute left-0 z-10 top-1/2 transform -translate-y-1/2 bg-gray-600 text-white px-4 py-2 rounded-full"
                  onClick={prevSlide}
                >
                  &#10094;
                </button>

                {/* Image Display */}
                <img
                  src={images[currentIndex]}
                  alt="tower"
                  className="rounded-lg object-cover w-full h-auto"
                />

                {/* Next arrow */}
                <button
                  className="modal-navigation1 absolute right-0 z-10 top-1/2 transform -translate-y-1/2 bg-gray-600 text-white px-4 py-2 rounded-full"
                  onClick={nextSlide}
                >
                  &#10095;
                </button>
              </div>
            </div>

            {/* Right Sidebar Section */}
            <div
              className="w-full xl:w-2/12  lg:w-2/12 md:w-12/12 w-full  bg-white border rounded-lg mt-6 xl:mt-0 lg:mt-0 "
              style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}
            >
              <div
                className="flex xl:flex-wrap lg:flex-wrap items-center justify-between xl:py-6 py-6"
                style={{ background: "#f0eeee" }}
              >
                <div className="pt-4 w-full px-6">
                  <div className="font xxl:text-base xl:text-base lg:text-xs text-sm font-semibold text-center text-white py-2 rounded bgbutton">
                    Indoor Amenities
                  </div>
                </div>
              </div>

              <ul className="list-disc list-inside px-4 pb-4 pt-2 xl:block lg:block ">
                <li className="xl:pt-2 pt-2 font xxl:text-sm xl:text-sm text-sm lg:text-xs"> Clubhouse</li>
                <li className="xl:pt-2 pt-2 font xxl:text-sm xl:text-sm text-sm lg:text-xs">Gymnasium</li>
                <li className="xl:pt-2 pt-2 font xxl:text-sm xl:text-sm text-sm lg:text-xs">Yoga, Pilates, Aerobics</li>
                <li className="xl:pt-2 pt-2 font xxl:text-sm xl:text-sm text-sm lg:text-xs">Indoor Game Room</li>
                <li className="xl:pt-2 pt-2 font xxl:text-sm xl:text-sm text-sm lg:text-xs">Mini theatre</li>
                <li className="xl:pt-2 pt-2 font xxl:text-sm xl:text-sm text-sm lg:text-xs">Library</li>
                <li className="xl:pt-2 pt-2 font xxl:text-sm xl:text-sm text-sm lg:text-xs">Convenience store </li>
                <li className="xl:pt-2 pt-2 font xxl:text-sm xl:text-sm text-sm lg:text-xs">Party Hall</li>
              </ul>




            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
