/*eslint-disable*/
import React, { useEffect, useState, useRef } from "react";
import towertypicala from "assets/img/typical/towertypicala.jpg";
import unita from "assets/img/typical/unita.jpg";
import unit1Typical from "assets/img/typical-3d/TOWER B UNIT 1.jpg";
import unit2Typical from "assets/img/typical-3d/TOWER B UNIT 2.jpg";
import unit3Typical from "assets/img/typical-3d/TOWER B UNIT 3.jpg";
import unit4Typical from "assets/img/typical-3d/TOWER B UNIT 4.jpg";
import unit1Refugee from "assets/img/refugee-3d/TOWER B REFUGE UNIT 1.jpg";
import unit2Refugee from "assets/img/refugee-3d/TOWER B REFUGE UNIT 2.jpg";
import IndexNavbarr from "components/Navbars/IndexNavbarr.js";
import "./ImageMapComponent.css";
import Footer from "components/Footers/Footer";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

export default function IsoB() {
  const history = useHistory();
  const [activeArea, setActiveArea] = useState(null);
  const [activeAreaName, setActiveAreaName] = useState("");
  const [activeElement, setActiveElement] = useState(null);
  const [imageSize, setImageSize] = useState({
    width: 0,
    height: 0,
    top: 0,
    left: 0,
  });
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const imgRef = useRef(null);
  const originalWidth = 886;
  const { floor, plan, unit } = useParams();
  const paddingX = 5;
  const paddingY = 5;

  const resizeImageMap = () => {
    const img = imgRef.current;
    const map = document.querySelector('map[name="image-map"]');
    const widthRatio = img.clientWidth / originalWidth;

    map.querySelectorAll("area").forEach((area) => {
      const originalCoords = area.dataset.coords.split(",").map(Number);
      const newCoords = originalCoords.map((coord) => coord * widthRatio);
      area.coords = newCoords.join(",");
    });
  };

  useEffect(() => {
    setIsTouchDevice("ontouchstart" in window || navigator.maxTouchPoints > 0);
    setActiveArea(null);
    resizeImageMap();

    window.addEventListener("resize", resizeImageMap);
    window.addEventListener("load", resizeImageMap);

    const img = document.querySelector("img[usemap]");
    if (img) {
      const updateImageSize = () => {
        const { clientWidth, clientHeight, offsetTop, offsetLeft } = img;
        setImageSize({
          width: clientWidth,
          height: clientHeight,
          top: offsetTop,
          left: offsetLeft,
        });
      };
      updateImageSize();
      window.addEventListener("load", updateImageSize);
      window.addEventListener("resize", updateImageSize);

      return () => window.removeEventListener("resize", updateImageSize);
    }

    return () => {
      window.removeEventListener("resize", resizeImageMap);
      window.removeEventListener("load", resizeImageMap);
    };
  }, []);

  const handleAreaClick = (area) => {
    setActiveArea(area.target.coords.split(",").map(Number));
    setActiveElement(area.target.id);
    setActiveAreaName(area.target.getAttribute("data-title"));
  };

  const handleAreaMouseEnter = (area) => {
    if (!isTouchDevice) {
      setActiveArea(area.target.coords.split(",").map(Number));
      setActiveElement(area.target.id);
      setActiveAreaName(area.target.getAttribute("data-title"));
    }
  };

  const handleAreaMouseLeave = (area) => {
    if (!isTouchDevice) {
      setActiveArea(null);
      setActiveElement(null);
      setActiveAreaName("");
    }
  };


  const [isZoomed, setIsZoomed] = useState(false);
  const [zoomedImage, setZoomedImage] = useState(null);

  // Object holding images for each plan type
  const images = {
    typical: [unit1Typical, unit2Typical, unit3Typical, unit4Typical],
    refugee: [unit2Refugee, unit1Refugee,  unit3Typical, unit4Typical],
    second: [unit1Typical, unit2Typical, unit3Typical,]
  };

  // Function to get a random category
  const getRandomCategory = () => {
    const categories = Object.keys(images); // ['typical', 'refugee', 'second']
    const randomCategory = categories[Math.floor(Math.random() * categories.length)];
    return randomCategory;
  };

  // Function to get a random image from a selected category
  const getRandomImage = () => {
    const randomCategory = getRandomCategory(); // Get a random category
    const categoryImages = images[randomCategory]; // Get images for the selected category
    const randomIndex = Math.floor(Math.random() * categoryImages.length); // Get a random image index
    return categoryImages[randomIndex]; // Return the selected image
  };

  // Example: Get random image from any of the three categories
  const imageToDisplay = getRandomImage(); // This will randomly pick an image from 'typical', 'refugee', or 'second'


  const zoomImage = (selectedPlan) => {
    if (images[selectedPlan]) {
      setZoomedImage(images[selectedPlan]);
      setIsZoomed(true);
    }
  };

  // Close the zoomed image
  const closeZoom = () => {
    setIsZoomed(false);
    setZoomedImage(null);
  };

  return (
    <>
      <IndexNavbarr fixed />

      <section className="relative h-screen xl:py-32 lg:pt-32 md:pt-24 xl:pb-12 pt-24 bg-gray-100">
        <div className="container mx-auto flex justify-center items-center">
          <div className="flex flex-wrap justify-between  lg:flex-row flex-col-reverse">
            <div className="w-full xl:w-2/12 lg:w-2/12 md:w-12/12 w-full  px-1 pt-2 ">
              <div
                className="bg-white  rounded-lg  "
                style={{
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                  height: "500px",
                  overflowY: "scroll",
                }}
              >
                {plan === "typical" && unit === "1" && (
                  <table className=" rounded-lg w-full py-2">
                    <tr
                      className="t_foyer"
                      style={
                        ["mainImage_1_11"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Entrance Foyer
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        4'6" x 6'3"
                      </td>
                    </tr>
                    <tr
                      className="t_living "
                      style={
                        ["mainImage_1_14"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Living/Dining{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'4" x 19'10" <br></br>& 3'8" x 12'2"
                      </td>
                    </tr>
                    <tr
                      className="t_balcony_1"
                      style={
                        ["mainImage_1_13"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Deck
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 4'7"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_1_10"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Kitchen
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'10" x 8'
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_1_7"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Dry Balcony
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'3" x 8'0"
                      </td>
                    </tr>
                    <tr
                      className="t_utility"
                      style={
                        ["mainImage_1_6"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Utility/Storage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        7' x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_1"
                      style={
                        ["mainImage_1_8"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Pooja Room
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        1'2" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_balcony"
                      style={
                        ["mainImage_1_16"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Bedroom 2 Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'5" x 3'7"
                      </td>
                    </tr>
                    <tr
                      className="t_passage"
                      style={
                        ["mainImage_1_17"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2 Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        2'10" × 1'18"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_2"
                      style={
                        ["mainImage_1_15"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'7"× 11'
                      </td>
                    </tr>
                    <tr
                      className="t_toilet_2"
                      style={
                        ["mainImage_1_18"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Toilet 2{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        8' x 5'
                      </td>
                    </tr>
                    <tr
                      className="t_master_passage"
                      style={
                        ["mainImage_1_9"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Passage 1
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5'× 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_bedroom"
                      style={
                        ["mainImage_1_12"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Bedroom 3
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_1_5"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Common Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        8' x 4'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_1_2"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'5" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_1_1"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Bedroom
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'10"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_1_3"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 2'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_1_4"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'4"
                      </td>
                    </tr>
                  </table>
                )}
                {plan === "typical" && unit === "2" && (
                  <table className=" rounded-lg w-full py-2">
                    <tr
                      className="t_foyer"
                      style={
                        ["mainImage_2_11"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Entrance Foyer
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        4'6" x 6'3"
                      </td>
                    </tr>
                    <tr
                      className="t_living "
                      style={
                        ["mainImage_2_17"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Living/Dining{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'4" x 19'10" <br></br>& 3'8" x 12'2"
                      </td>
                    </tr>
                    <tr
                      className="t_balcony_1"
                      style={
                        ["mainImage_2_16"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Deck
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 4'7"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_2_10"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Kitchen
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'10" x 8'
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_2_9"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Dry Balcony
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'3" x 8'0"
                      </td>
                    </tr>
                    <tr
                      className="t_utility"
                      style={
                        ["mainImage_2_8"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Utility/Storage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        7' x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_1"
                      style={
                        ["mainImage_2_0"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Pooja Room
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        1'2" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_balcony"
                      style={
                        ["mainImage_2_15"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Bedroom 2 Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'5" x 3'7"
                      </td>
                    </tr>
                    <tr
                      className="t_passage"
                      style={
                        ["mainImage_2_14"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2 Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        2'10" × 1'18"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_2"
                      style={
                        ["mainImage_2_12"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'7"× 11'
                      </td>
                    </tr>
                    <tr
                      className="t_toilet_2"
                      style={
                        ["mainImage_2_13"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Toilet 2{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        8' x 5'
                      </td>
                    </tr>
                    <tr
                      className="t_master_passage"
                      style={
                        ["mainImage_2_6"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Passage 1
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5'× 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_bedroom"
                      style={
                        ["mainImage_2_7"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Bedroom 3
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_2_5"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Common Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        8' x 4'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_2_2"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'5" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_2_1"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Bedroom
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'10"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_2_4"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 2'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_2_3"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'4"
                      </td>
                    </tr>
                  </table>
                )}
                {plan === "typical" && unit === "3" && (
                  <table className=" rounded-lg w-full py-2">
                    <tr
                      className="t_living "
                      style={
                        ["mainImage_3_10"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Living/Dining{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 19'4" <br></br>& 6'3" x 11'8"
                      </td>
                    </tr>
                    <tr
                      className="t_balcony_1"
                      style={
                        ["mainImage_3_9"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Deck
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 4'7"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_3_7"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Kitchen
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'6" x 8'4"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_3_6"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Dry Balcony
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'3" x 7'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_balcony"
                      style={
                        ["mainImage_3_5"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_2"
                      style={
                        ["mainImage_3_8"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'× 12'
                      </td>
                    </tr>
                    <tr
                      className="t_toilet_2"
                      style={
                        ["mainImage_3_4"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Common Toilet{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_3_1"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Bedroom
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_3_3"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        6'3" x 2'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_3_2"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'
                      </td>
                    </tr>
                  </table>
                )}
                {plan === "typical" && unit === "4" && (
                  <table className=" rounded-lg w-full py-2">
                    <tr
                      className="t_living "
                      style={
                        ["mainImage_4_11"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Living/Dining{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 19'14" <br></br>& 5'5" x 11'10"
                      </td>
                    </tr>
                    <tr
                      className="t_balcony_1"
                      style={
                        ["mainImage_4_10"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Deck
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 4'7"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_4_9"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Kitchen
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'6" x 8'4"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_4_8"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Dry Balcony
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'3" x 8'4"
                      </td>
                    </tr>
                    <tr
                      className="t_master_balcony"
                      style={
                        ["mainImage_4_3"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        12'2" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_passage"
                      style={
                        ["mainImage_4_6"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 3
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'8" × 12'10"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_2"
                      style={
                        ["mainImage_4_7"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Common Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5'× 8'
                      </td>
                    </tr>
                    <tr
                      className="t_toilet_2"
                      style={
                        ["mainImage_4_5"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Bedroom 2{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'10" x 12'10"
                      </td>
                    </tr>
                    <tr
                      className="t_master_passage"
                      style={
                        ["mainImage_4_4"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Toilet 2
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5'× 7'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_bedroom"
                      style={
                        ["mainImage_4_1"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Master Bedroom
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        12'6" x 11'10"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_4_2"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        8'8" x 5'
                      </td>
                    </tr>
                  </table>
                )}
                {plan === "second" && unit === "1" && (
                  <table className=" rounded-lg w-full py-2">
                    <tr
                      className="t_foyer"
                      style={
                        ["mainImage_1_11"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Entrance Foyer
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        4'6" x 6'3"
                      </td>
                    </tr>
                    <tr
                      className="t_living "
                      style={
                        ["mainImage_1_14"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Living/Dining{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'4" x 19'10" <br></br>& 3'8" x 12'2"
                      </td>
                    </tr>
                    <tr
                      className="t_balcony_1"
                      style={
                        ["mainImage_1_13"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Deck
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 4'7"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_1_10"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Kitchen
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'10" x 8'
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_1_7"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Dry Balcony
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'3" x 8'0"
                      </td>
                    </tr>
                    <tr
                      className="t_utility"
                      style={
                        ["mainImage_1_6"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Utility/Storage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        7' x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_1"
                      style={
                        ["mainImage_1_8"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Pooja Room
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        1'2" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_balcony"
                      style={
                        ["mainImage_1_16"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Bedroom 2 Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'5" x 3'7"
                      </td>
                    </tr>
                    <tr
                      className="t_passage"
                      style={
                        ["mainImage_1_17"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2 Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        2'10" × 1'18"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_2"
                      style={
                        ["mainImage_1_15"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'7"× 11'
                      </td>
                    </tr>
                    <tr
                      className="t_toilet_2"
                      style={
                        ["mainImage_1_18"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Toilet 2{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        8' x 5'
                      </td>
                    </tr>
                    <tr
                      className="t_master_passage"
                      style={
                        ["mainImage_1_9"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Passage 1
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5'× 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_bedroom"
                      style={
                        ["mainImage_1_12"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Bedroom 3
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_1_5"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Common Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        8' x 4'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_1_2"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'5" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_1_1"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Bedroom
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'10"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_1_3"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 2'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_1_4"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'4"
                      </td>
                    </tr>
                  </table>
                )}
                {plan === "second" && unit === "2" && (
                  <table className=" rounded-lg w-full py-2">
                    <tr
                      className="t_foyer"
                      style={
                        ["mainImage_2_11"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Entrance Foyer
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        4'6" x 6'3"
                      </td>
                    </tr>
                    <tr
                      className="t_living "
                      style={
                        ["mainImage_2_17"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Living/Dining{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'4" x 19'10" <br></br>& 3'8" x 12'2"
                      </td>
                    </tr>
                    <tr
                      className="t_balcony_1"
                      style={
                        ["mainImage_2_16"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Deck
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 4'7"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_2_10"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Kitchen
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'10" x 8'
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_2_9"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Dry Balcony
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'3" x 8'0"
                      </td>
                    </tr>
                    <tr
                      className="t_utility"
                      style={
                        ["mainImage_2_8"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Utility/Storage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        7' x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_1"
                      style={
                        ["mainImage_2_11"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Pooja Room
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        1'2" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_balcony"
                      style={
                        ["mainImage_2_15"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Bedroom 2 Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'5" x 3'7"
                      </td>
                    </tr>
                    <tr
                      className="t_passage"
                      style={
                        ["mainImage_2_14"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2 Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        2'10" × 1'18"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_2"
                      style={
                        ["mainImage_2_12"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'7"× 11'
                      </td>
                    </tr>
                    <tr
                      className="t_toilet_2"
                      style={
                        ["mainImage_2_13"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Toilet 2{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        8' x 5'
                      </td>
                    </tr>
                    <tr
                      className="t_master_passage"
                      style={
                        ["mainImage_2_6"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Passage 1
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5'× 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_bedroom"
                      style={
                        ["mainImage_2_7"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Bedroom 3
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_2_5"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Common Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        8' x 4'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_2_2"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'5" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_2_1"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Bedroom
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'10"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_2_4"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 2'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_2_3"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'4"
                      </td>
                    </tr>
                  </table>
                )}
                {plan === "second" && unit === "3" && (
                  <table className=" rounded-lg w-full py-2">
                    <tr
                      className="t_living "
                      style={
                        ["mainImage_3_10"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Living/Dining{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 19'4" <br></br>& 6'3" x 11'8"
                      </td>
                    </tr>
                    <tr
                      className="t_balcony_1"
                      style={
                        ["mainImage_3_9"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Deck
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 4'7"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_3_7"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Kitchen
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'6" x 8'4"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_3_6"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Dry Balcony
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'3" x 7'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_balcony"
                      style={
                        ["mainImage_3_5"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_2"
                      style={
                        ["mainImage_3_8"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'× 12'
                      </td>
                    </tr>
                    <tr
                      className="t_toilet_2"
                      style={
                        ["mainImage_3_4"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Common Toilet{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_3_1"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Bedroom
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_3_3"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        6'3" x 2'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_3_2"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'
                      </td>
                    </tr>
                  </table>
                )}
                {plan === "refugee" && unit === "1" && (
                  <table className=" rounded-lg w-full py-2">
                    <tr
                      className="t_living "
                      style={
                        ["s_mainImage_1_9"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Entrance Foyer{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        4'6" x 6'3"
                      </td>
                    </tr>
                    <tr
                      className="t_living "
                      style={
                        ["s_mainImage_1_14"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Living/Dining{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'4" x 19'10"{" "}
                      </td>
                    </tr>
                    <tr
                      className="t_balcony_1"
                      style={
                        ["s_mainImage_1_13"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Deck
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 4'7"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["s_mainImage_1_8"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Study Room
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        6'8" x 8'4"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["s_mainImage_1_10"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Kitchen
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'10" x 8'
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["s_mainImage_1_7"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Dry Balcony
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'3" x 8'0"
                      </td>
                    </tr>
                    <tr
                      className="t_master_balcony"
                      style={
                        ["s_mainImage_1_11"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'0" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_2"
                      style={
                        ["s_mainImage_1_15"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'× 12'
                      </td>
                    </tr>
                    <tr
                      className="t_toilet_2"
                      style={
                        ["s_mainImage_1_5"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Common Toilet{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5'0" x 8'4"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["s_mainImage_1_2"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Bedroom Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        2'4" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["s_mainImage_1_1"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Bedroom
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'10"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["s_mainImage_1_3"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 2'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["s_mainImage_1_4"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'4"
                      </td>
                    </tr>
                  </table>
                )}
                {plan === "refugee" && unit === "2" && (
                  <table className=" rounded-lg w-full py-2">
                    <tr
                      className="t_living "
                      style={
                        ["s_mainImage_2_6"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Entrance Foyer{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        4'6" x 6'3"
                      </td>
                    </tr>
                    <tr
                      className="t_living "
                      style={
                        ["s_mainImage_2_17"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Living/Dining{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'4" x 19'10"{" "}
                      </td>
                    </tr>
                    <tr
                      className="t_balcony_1"
                      style={
                        ["s_mainImage_2_16"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Deck
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 4'7"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["s_mainImage_2_15"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Study Room
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        6'8" x 8'4"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["s_mainImage_2_10"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Kitchen
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'10" x 8'
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["s_mainImage_2_9"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Dry Balcony
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'3" x 8'0"
                      </td>
                    </tr>
                    <tr
                      className="t_master_balcony"
                      style={
                        ["s_mainImage_2_11"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'0" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_2"
                      style={
                        ["s_mainImage_2_12"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'× 12'
                      </td>
                    </tr>
                    <tr
                      className="t_toilet_2"
                      style={
                        ["s_mainImage_2_13"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Common Toilet{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5'0" x 8'4"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["s_mainImage_2_2"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Bedroom Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        2'4" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["s_mainImage_2_1"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Bedroom
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'10"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["s_mainImage_2_4"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 2'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["s_mainImage_2_3"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'4"
                      </td>
                    </tr>
                  </table>
                )}
                {plan === "refugee" && unit === "3" && (
                  <table className=" rounded-lg w-full py-2">
                    <tr
                      className="t_living "
                      style={
                        ["mainImage_3_10"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Living/Dining{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 19'4" <br></br>& 6'3" x 11'8"
                      </td>
                    </tr>
                    <tr
                      className="t_balcony_1"
                      style={
                        ["mainImage_3_9"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Deck
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 4'7"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_3_7"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Kitchen
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'6" x 8'4"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_3_6"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Dry Balcony
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'3" x 7'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_balcony"
                      style={
                        ["mainImage_3_5"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_2"
                      style={
                        ["mainImage_3_8"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 2
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'× 12'
                      </td>
                    </tr>
                    <tr
                      className="t_toilet_2"
                      style={
                        ["mainImage_3_4"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Common Toilet{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_3_1"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Bedroom
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11' x 12'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_3_3"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Wardrobe
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        6'3" x 2'
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_3_2"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5' x 8'
                      </td>
                    </tr>
                  </table>
                )}
                {plan === "refugee" && unit === "4" && (
                  <table className=" rounded-lg w-full py-2">
                    <tr
                      className="t_living "
                      style={
                        ["mainImage_4_11"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Living/Dining{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs"
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 19'14" <br></br>& 5'5" x 11'10"
                      </td>
                    </tr>
                    <tr
                      className="t_balcony_1"
                      style={
                        ["mainImage_4_10"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Deck
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        11'2" x 4'7"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_4_9"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Kitchen
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'6" x 8'4"
                      </td>
                    </tr>
                    <tr
                      className="t_kitchen"
                      style={
                        ["mainImage_4_8"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Dry Balcony
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        3'3" x 8'4"
                      </td>
                    </tr>
                    <tr
                      className="t_master_balcony"
                      style={
                        ["mainImage_4_3"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Passage
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        12'2" x 3'5"
                      </td>
                    </tr>
                    <tr
                      className="t_passage"
                      style={
                        ["mainImage_4_6"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Bedroom 3
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'8" × 12'10"
                      </td>
                    </tr>
                    <tr
                      className="t_bedroom_2"
                      style={
                        ["mainImage_4_7"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Common Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5'× 8'
                      </td>
                    </tr>
                    <tr
                      className="t_toilet_2"
                      style={
                        ["mainImage_4_5"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Bedroom 2{" "}
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        10'10" x 12'10"
                      </td>
                    </tr>
                    <tr
                      className="t_master_passage"
                      style={
                        ["mainImage_4_4"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Toilet 2
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        5'× 7'5"
                      </td>
                    </tr>
                    <tr
                      className="t_master_bedroom"
                      style={
                        ["mainImage_4_1"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : {}
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        {" "}
                        Master Bedroom
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        12'6" x 11'10"
                      </td>
                    </tr>
                    <tr
                      className="t_master_toilet"
                      style={
                        ["mainImage_4_2"].includes(activeElement)
                          ? { backgroundColor: "#d8b387", color: "white" }
                          : { backgroundColor: "#f0eeee8a" }
                      }
                    >
                      <td
                        className="py-2 font text-xs font-semibold px-2"
                        style={{ padding: "2px;" }}
                      >
                        Master Toilet
                      </td>
                      <td
                        className="tablefont py-2 font text-xs "
                        style={{ padding: "2px;", minWidth: "70px" }}
                      >
                        8'8" x 5'
                      </td>
                    </tr>
                  </table>
                )}
              </div>
              <div className="px-2 pt-12 pb-16 xl:hidden lg:hidden block">
                <h1
                  className="font-bold uppercase text-sm font"
                  style={{ color: "#be935f" }}
                >
                  Apartment Features
                </h1>
                <p className="xl:pt-4 pt-2 font font-semibold text-sm">
                  1. Homes have wide sundecks
                </p>
                <p className="xl:pt-2 pt-2 font font-semibold text-sm">
                  2. Internal Flooring
                </p>
                <p className=" font text-sm">
                  Large format Vitrified tiles, anti-skid Vitrified tiles in
                  balcony and utility room
                </p>
                <p className="xl:pt-2 pt-2 font font-semibold text-sm">
                  3. Windows
                </p>
                <p className=" font text-sm">
                  Heavy Duty aluminium Single-glazed windows
                </p>
                <p className="xl:pt-2 pt-2 font font-semibold text-sm">
                  4. Doors
                </p>
                <p className=" font text-sm">
                  Main entrance door in veneer with melamine finish & laminate
                  on all internal doors
                </p>
              </div>
            </div>

            <div className="w-full xl:w-8/12 lg:w-8/12 md:w-12/12 px-6 xl:pt-0 lg:pt-0 md:pt-0 pt-6 pb-2">
              {/* <img src={unita} usemap="#image-map" ref={imgRef} style={{ maxWidth: '100%', height: 'auto', width: '100%' }}/> */}
              {plan === "typical" && (
                <>
                  {unit === "1" && (
                    <>
                      <img
                        src={unit1Typical}
                        useMap="#image-map"
                        ref={imgRef}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "100%",
                        }}
                      />
                      <map name="image-map">
                        <area
                          target=""
                          alt="master bedroom"
                          data-title={"Master Bedroom\n 11' x 12'10\""}
                          href=""
                          data-coords="507,118,508,93,607,50,668,150,656,177,562,218"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_1"
                        />
                        <area
                          target=""
                          alt="master passage"
                          data-title={"Master Passage\n 3'5\" x 3'5\""}
                          href=""
                          data-coords="531,230,515,201,545,189,561,217"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_2"
                        />
                        <area
                          target=""
                          alt="master wardrobe"
                          data-title={"Master Wardrobe\n 5' x 2'"}
                          href=""
                          data-coords="566,215,614,195,622,209,576,230"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_3"
                        />
                        <area
                          target=""
                          alt="master toilet"
                          data-title={"Master Toilet\n 5' x 8'4\""}
                          href=""
                          data-coords="615,195,656,177,668,152,696,199,705,195,724,225,707,244,659,265"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_4"
                        />
                        <area
                          target=""
                          alt="common Toilet"
                          data-title={"Common Toilet\n 8' x 4'5\""}
                          href=""
                          data-coords="485,253,554,223,573,261,507,292"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_5"
                        />
                        <area
                          target=""
                          alt="utility/storage"
                          data-title={"Utility/Storage\n 7' x 3'5\""}
                          href=""
                          data-coords="518,291,581,261,599,292,535,322"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_6"
                        />
                        <area
                          target=""
                          alt="dry balcony"
                          data-title={"Dry Balcony\n 3'3\" x 8'0\""}
                          href=""
                          data-coords="533,325,560,311,602,386,573,401"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_7"
                        />
                        <area
                          target=""
                          alt="pooja room"
                          data-title={"Pooja Room\n 1'2\" x 3'5\""}
                          href=""
                          data-coords="505,296,519,289,534,322,522,329"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_8"
                        />
                        <area
                          target=""
                          alt="passage 1"
                          data-title={"Passage 1\n 5' x 3'5\""}
                          href=""
                          data-coords="377,395,423,374,453,434,407,457"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_9"
                        />
                        <area
                          target=""
                          alt="kitchen"
                          data-title={"Kitchen\n 10'10\" x 8'"}
                          href=""
                          data-coords="465,455,426,374,530,328,572,399"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_10"
                        />
                        <area
                          target=""
                          alt="passage 2"
                          data-title={"Entrance Foyer\n 4'6\" x 6'3\""}
                          href=""
                          data-coords="468,223,515,202,530,230,482,252"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_11"
                        />
                        <area
                          target=""
                          alt="bedroom 3"
                          data-title={"Bedroom 3\n 11' x 12'"}
                          href=""
                          data-coords="385,116,400,147,404,173,432,235,534,188,496,120,499,94,485,71"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_12"
                        />
                        <area
                          target=""
                          alt="deck"
                          data-title={"Deck\n 11'2\" x 4'7\""}
                          href=""
                          data-coords="518,100,628,142,611,182,500,137"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_13"
                        />
                        <area
                          target=""
                          alt="Living / Dining"
                          data-title={
                            "Living / Dining\n 11'4\" x 19'10\" & 3'8\" x 12'2\""
                          }
                          href=""
                          data-coords="290,195,298,216,378,394,519,329,466,223,434,235,401,173,396,150"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_14"
                        />
                        <area
                          target=""
                          alt="Bedroom 2"
                          data-title={"Bedroom 2\n 11'7\" x 11'"}
                          href=""
                          data-coords="163,247,176,268,214,361,327,311,287,224,278,200"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_15"
                        />
                        <area
                          target=""
                          alt="Bedroom 2 Passage"
                          data-title={"Bedroom 2 Passage\n 3'5\" x 3'7\""}
                          href=""
                          data-coords="297,332,339,313,351,341,309,359"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_16"
                        />
                        <area
                          target=""
                          alt="Bedroom 2 Wardrobe"
                          data-title={"Bedroom 2 Wardrobe\n 2'10\" x 1'18\""}
                          href=""
                          data-coords="309,360,337,348,346,362,316,376"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_17"
                        />
                        <area
                          target=""
                          alt="Bedroom 2 Toilet"
                          data-title={"Toilet 2 \n 8' x 5'"}
                          href=""
                          data-coords="214,365,234,411,314,376,294,330"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_18"
                        />
                      </map>
                    </>
                  )}
                  {unit === "2" && (
                    <>
                      <img
                        src={unit2Typical}
                        useMap="#image-map"
                        ref={imgRef}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "100%",
                        }}
                      />
                      <map name="image-map">
                        <area
                          target=""
                          alt="master bedroom"
                          data-title={"Master Bedroom\n 11' x 12'10\""}
                          href=""
                          data-coords="545,167,631,132,640,94,713,181,699,188,690,221,608,259"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_1"
                        />
                        <area
                          target=""
                          alt="master passage"
                          data-title={"Master Passage\n 3'5\" x 3'5\""}
                          href=""
                          data-coords="512,182,543,169,558,192,528,204"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_2"
                        />
                        <area
                          target=""
                          alt="master toilet"
                          data-title={"Master Toilet\n 5' x 8'4\""}
                          href=""
                          data-coords="561,109,562,87,554,77,556,58,600,40,618,58,612,61,638,93,631,130,591,146"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_3"
                        />
                        <area
                          target=""
                          alt="master wardrobe"
                          data-title={"Master Wardrobe\n 5' x 2'"}
                          href=""
                          data-coords="537,153,579,135,589,147,546,166"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_4"
                        />
                        <area
                          target=""
                          alt="common bathroom"
                          data-title={"Common Toilet\n 8' x 4'5\""}
                          href=""
                          data-coords="444,166,512,139,531,170,465,197"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_5"
                        />
                        <area
                          target=""
                          alt="passage 1"
                          data-title={"Passage 1\n 5' x 3'5\""}
                          href=""
                          data-coords="469,198,511,181,527,206,481,224"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_6"
                        />
                        <area
                          target=""
                          alt="bedroom 3"
                          data-title={"Bedroom 3\n 11' x 12'"}
                          href=""
                          data-coords="450,238,552,198,617,289,512,330"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_7"
                        />
                        <area
                          target=""
                          alt="utility / storage"
                          data-title={"Utility/Storage\n 7' x 3'5\""}
                          href=""
                          data-coords="438,137,497,112,515,136,454,160"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_8"
                        />
                        <area
                          target=""
                          alt="dry balcony"
                          data-title={"Dry Balcony\n 3'3\" x 8'0\""}
                          href=""
                          data-coords="404,79,402,63,401,49,426,40,428,81,458,123,438,132"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_9"
                        />
                        <area
                          target=""
                          alt="kitchen"
                          data-title={"Kitchen\n 10'10\" x 8'"}
                          href=""
                          data-coords="309,123,306,104,294,86,396,51,401,78,436,134,333,173"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_10"
                        />
                        <area
                          target=""
                          alt="passage 2"
                          data-title={"Entrance Foyer\n 4'6\" x 6'3\""}
                          href=""
                          data-coords="258,116,298,102,335,171,295,185"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_11"
                        />
                        <area
                          target=""
                          alt="bedroom 2"
                          data-title={"Bedroom 2\n 11'7\" x 11'"}
                          href=""
                          data-coords="195,307,312,264,360,356,243,406"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_12"
                        />
                        <area
                          target=""
                          alt="bedroom 2 toilet"
                          data-title={"Toilet 2 \n 8' x 5'"}
                          href=""
                          data-coords="167,237,195,306,275,273,263,253,258,223,247,203"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_13"
                        />
                        <area
                          target=""
                          alt="bedroom 2 wardrobe"
                          data-title={"Bedroom 2 Wardrobe\n 2'10\" x 1'18\""}
                          href=""
                          data-coords="263,245,287,235,294,248,268,259"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_14"
                        />
                        <area
                          target=""
                          alt="bedroom 2 passage"
                          data-title={"Bedroom 2 Passage\n 3'5\" x 3'7\""}
                          href=""
                          data-coords="267,258,309,241,320,258,278,277"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_15"
                        />
                        <area
                          target=""
                          alt="deck"
                          data-title={"Deck\n 11'2\" x 4'7\""}
                          href=""
                          data-coords="373,345,484,295,504,323,503,353,398,398,394,388"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_16"
                        />
                        <area
                          target=""
                          alt="Living / Dining"
                          data-title={
                            "Living / Dining\n 11'4\" x 19'10\" & 3'8\" x 12'2\""
                          }
                          href=""
                          data-coords="290,190,426,140,480,224,447,239,482,292,371,343"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_17"
                        />
                      </map>
                    </>
                  )}
                  {unit === "3" && (
                    <>
                      <img
                        src={unit3Typical}
                        useMap="#image-map"
                        ref={imgRef}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "100%",
                        }}
                      />
                      <map name="image-map">
                        <area
                          target=""
                          alt="master bedroom"
                          data-title={"Master Bedroom\n 11' x 12'"}
                          href=""
                          data-coords="446,289,537,226,545,201,634,290,619,317,530,385"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_1"
                        />
                        <area
                          target=""
                          alt="master toilet"
                          data-title={"Master Toilet\n 5' x 8'"}
                          href=""
                          data-coords="420,395,489,345,523,386,453,439"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_2"
                        />
                        <area
                          target=""
                          alt="master wardrobe"
                          data-title={"Master Wardrobe\n 6'3\" x 2'"}
                          href=""
                          data-coords="428,301,446,290,491,341,472,355"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_3"
                        />
                        <area
                          target=""
                          alt="common toilet"
                          data-title={"Common Toilet\n 5' x 8'"}
                          href=""
                          data-coords="343,298,409,253,442,291,375,340"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_4"
                        />
                        <area
                          target=""
                          alt="master passage"
                          data-title={"Passage\n 5' x 3'5\""}
                          href=""
                          data-coords="411,252,439,233,473,271,443,289"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_5"
                        />
                        <area
                          target=""
                          alt="dry balcony"
                          data-title={"Dry Balcony\n 3'3\" x 7'5\""}
                          href=""
                          data-coords="301,396,370,348,393,380,323,428"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_6"
                        />
                        <area
                          target=""
                          alt="kitchen"
                          data-title={"Kitchen\n 10'6\"  x 8'4\""}
                          href=""
                          data-coords="233,307,304,258,370,342,297,397"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_7"
                        />
                        <area
                          target=""
                          alt="bedroom 2"
                          data-title={"Bedroom 2\n 10' x 12'"}
                          href=""
                          data-coords="412,196,468,162,466,131,500,113,569,178,541,197,537,224,473,269"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_8"
                        />
                        <area
                          target=""
                          alt="deck"
                          data-title={"Deck\n 11'2\" x 4'7\""}
                          href=""
                          data-coords="398,57,428,35,497,101,465,122"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_9"
                        />
                        <area
                          target=""
                          alt="living / dining"
                          data-title={
                            "Living/Dining \n 11'2\" x 19'4\" & 6'3\" x 11'8\""
                          }
                          href=""
                          data-coords="230,152,241,183,339,298,436,232,401,190,458,155,459,121,394,56"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_10"
                        />
                      </map>
                    </>
                  )}
                  {unit === "4" && (
                    <>
                      <img
                        src={unit4Typical}
                        useMap="#image-map"
                        ref={imgRef}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "100%",
                        }}
                      />
                      <map name="image-map">
                        <area
                          target=""
                          alt="master bedroom"
                          data-title={"Master Bedroom\n 12'6\" x 11'10\""}
                          href=""
                          data-coords="533,202,543,179,645,236,634,259,583,341,477,281"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_1"
                        />
                        <area
                          target=""
                          alt="master toilet"
                          data-title={"Master Toilet\n 8'8\" x 5'"}
                          href=""
                          data-coords="501,353,527,315,602,360,577,400"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_2"
                        />
                        <area
                          target=""
                          alt="master passage"
                          data-title={" Passage\n 12'2\" x 3'5\""}
                          href=""
                          data-coords="382,231,401,206,489,256,472,281"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_3"
                        />
                        <area
                          target=""
                          alt="bedroom 2 bathroom"
                          data-title={"Toilet 2\n 5' x 7'5\""}
                          href=""
                          data-coords="462,418,500,357,543,384,503,445"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_4"
                        />
                        <area
                          target=""
                          alt="bedroom 2"
                          data-title={" Bedroom 2 \n 10'10\" x 12'10\""}
                          href=""
                          data-coords="370,357,436,264,523,314,457,414"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_5"
                        />
                        <area
                          target=""
                          alt="bedroom 3"
                          data-title={" Bedroom 3 \n 10'8\" x 12'10\""}
                          href=""
                          data-coords="281,300,346,213,427,258,360,349"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_6"
                        />
                        <area
                          target=""
                          alt="common toilet"
                          data-title={"Common Toilet\n 5' x 8'"}
                          href=""
                          data-coords="401,203,442,152,480,172,442,225"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_7"
                        />
                        <area
                          target=""
                          alt="dry balcony"
                          data-title={"Dry Balcony \n 3'3\" x 8'4\""}
                          href=""
                          data-coords="497,180,523,142,530,115,541,103,568,116,557,150,530,197"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_8"
                        />
                        <area
                          target=""
                          alt="kitchen"
                          data-title={"Kitchen \n 10'6\" x 8'4\""}
                          href=""
                          data-coords="416,140,496,178,522,140,530,110,539,101,457,59,413,109"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_9"
                        />
                        <area
                          target=""
                          alt="deck"
                          data-title={"Deck\n 11'2\" x 4'7\""}
                          href=""
                          data-coords="172,214,201,183,218,210,283,250,290,261,266,290,262,299,197,259"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_10"
                        />
                        <area
                          target=""
                          alt="living / dining"
                          data-title={
                            "Living/Dining\n 11'2\" x 19'14\" & 5'5\" x 11'10\""
                          }
                          href=""
                          data-coords="297,256,337,202,379,227,436,151,415,140,411,110,317,55,205,179,211,186,222,210"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_11"
                        />
                      </map>
                    </>
                  )}
                </>
              )}
              {plan === "refugee" && (
                <>
                  {unit === "1" && (
                    <>
                      {/* Change Unit 1 Refugee Image here */}
                      <img
                        src={unit1Refugee}
                        useMap="#image-map"
                        ref={imgRef}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "100%",
                        }}
                      />
                      <map name="image-map">
                        <area
                          target=""
                          alt="master bedroom"
                          data-title={"Master Bedroom\n 11' x 12'10\""}
                          href=""
                          data-coords="253,211,244,183,232,175,325,87,333,117,370,142,415,173,392,198,328,267"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_1"
                        />
                        <area
                          target=""
                          alt="master passage"
                          data-title={"Master Bedroom Passage\n 2'4\" x 3'5\""}
                          href=""
                          data-coords="393,196,415,174,434,188,413,212"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_2"
                        />
                        <area
                          target=""
                          alt="master wardrobe"
                          data-title={"Master Wardrobe\n 5' x 2'"}
                          href=""
                          data-coords="371,141,384,130,426,157,412,170"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_3"
                        />
                        <area
                          target=""
                          alt="master toilet"
                          data-title={"Master Toilet\n 5' x 8'4\""}
                          href=""
                          data-coords="328,83,363,48,355,43,378,26,426,55,428,113,411,132,404,106,370,141,335,116"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_4"
                        />
                        <area
                          target=""
                          alt="common bathroom"
                          data-title={"Common Toilet\n 5'0\" x 8'4\""}
                          href=""
                          data-coords="424,178,473,128,449,113,474,88,518,113,514,147,463,207"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_5"
                        />
                        <area
                          target=""
                          alt="dry balcony"
                          data-title={"Dry Balcony\n 3'3\" x 8'0\""}
                          href=""
                          data-coords="518,145,520,128,556,88,580,103,534,156"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_7"
                        />
                        <area
                          target=""
                          alt="pooja room"
                          data-title={"Study Room\n 6'8\" x 8'4\""}
                          href=""
                          data-coords="466,205,518,148,566,177,514,235"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_8"
                        />
                        <area
                          target=""
                          alt="passage 1"
                          data-title={"Entrance foyer\n 4'6\" x 6'3\""}
                          href=""
                          data-coords="633,217,676,245,709,201,667,176"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_9"
                        />
                        <area
                          target=""
                          alt="kitchen"
                          data-title={"Kitchen\n 10'10\" x 8'"}
                          href=""
                          data-coords="535,156,585,101,677,160,631,216"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_10"
                        />
                        <area
                          target=""
                          alt="passage 2"
                          data-title={"Passage\n 10'0\" x 3'5\""}
                          href=""
                          data-coords="415,212,435,188,518,243,495,269"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_11"
                        />
                        <area
                          target=""
                          alt="deck"
                          data-title={"Deck\n 11'2\" x 4'7\""}
                          href=""
                          data-coords="412,371,442,334,546,409,514,451,510,464,413,393"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_13"
                        />
                        <area
                          target=""
                          alt="living / dining"
                          data-title={"Living/Dining\n 11'4\" x 19'10\""}
                          href=""
                          data-coords="443,333,519,243,512,238,568,177,675,245,546,406"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_14"
                        />
                        <area
                          target=""
                          alt="bedroom 2"
                          data-title={"Bedroom 2\n 11' x 12'"}
                          href=""
                          data-coords="320,309,313,299,400,203,493,269,407,374"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_1_15"
                        />
                      </map>
                    </>
                  )}
                  {unit === "2" && (
                    <>
                      {/* Change Unit 2 Refugee Image here */}
                      <img
                        src={unit2Refugee}
                        useMap="#image-map"
                        ref={imgRef}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "100%",
                        }}
                      />
                      <map name="image-map">
                        <area
                          target=""
                          alt="master bedroom"
                          data-title={"Master Bedroom\n 11' x 12'10\""}
                          href=""
                          data-coords="526,183,622,146,630,110,637,107,707,210,692,216,679,261,602,290,598,283,587,288"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_1"
                        />
                        <area
                          target=""
                          alt="master passage"
                          data-title={"Master Bedroom Passage\n 2'4\" x 3'5\""}
                          href=""
                          data-coords="499,191,524,182,541,210,515,220"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_2"
                        />
                        <area
                          target=""
                          alt="master toilet"
                          data-title={"Master Toilet\n 5' x 8'4\""}
                          href=""
                          data-coords="552,122,554,100,542,83,542,62,596,45,608,65,633,107,623,144,579,161"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_3"
                        />
                        <area
                          target=""
                          alt="master wardrobe"
                          data-title={"Master Wardrobe\n 5' x 2'"}
                          href=""
                          data-coords="518,165,566,148,576,163,527,181"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_4"
                        />
                        <area
                          target=""
                          alt="passage 1"
                          data-title={"Entrance foyer\n 4'6\" x 6'3\""}
                          href=""
                          data-coords="213,110,262,94,294,180,247,196"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_6"
                        />
                        <area
                          target=""
                          alt="dry balcony"
                          data-title={"Dry Balcony\n 3'3\" x 8'0\""}
                          href=""
                          data-coords="377,47,405,38,411,86,436,127,405,140,382,96"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_9"
                        />
                        <area
                          target=""
                          alt="kitchen"
                          data-title={"Kitchen\n 10'10\" x 8'"}
                          href=""
                          data-coords="262,79,370,46,376,63,381,94,407,142,295,181,270,100"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_10"
                        />
                        <area
                          target=""
                          alt="passage 2"
                          data-title={"Passage\n 10'0\" x 3'5\""}
                          href=""
                          data-coords="397,225,496,191,512,221,411,256"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_11"
                        />
                        <area
                          target=""
                          alt="bedroom 2"
                          data-title={"Bedroom 2\n 11' x 12'"}
                          href=""
                          data-coords="598,329,530,215,412,256,470,377"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_12"
                        />
                        <area
                          target=""
                          alt="bedroom 2 toilet"
                          data-title={"Common Toilet\n 5'0\" x 8'4\""}
                          href=""
                          data-coords="428,133,477,118,516,184,465,200"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_13"
                        />
                        <area
                          target=""
                          alt="bedroom 2 passage"
                          data-title={"Study Room\n 6'8\" x 8'4\""}
                          href=""
                          data-coords="365,159,427,140,463,202,397,224"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_15"
                        />
                        <area
                          target=""
                          alt="deck"
                          data-title={"Deck\n 11'2\" x 4'7\""}
                          href=""
                          data-coords="320,374,327,396,342,434,465,388,466,376,444,330"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_16"
                        />
                        <area
                          target=""
                          alt="living / dining"
                          data-title={"Living/Dining\n 11'4\" x 19'10\""}
                          href=""
                          data-coords="247,196,360,158,443,329,318,374"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="s_mainImage_2_17"
                        />
                      </map>
                    </>
                  )}
                  {unit === "3" && (
                    <>
                      <img
                        src={unit3Typical}
                        useMap="#image-map"
                        ref={imgRef}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "100%",
                        }}
                      />
                      <map name="image-map">
                        <area
                          target=""
                          alt="master bedroom"
                          data-title={"Master Bedroom\n 11' x 12'"}
                          href=""
                          data-coords="446,289,537,226,545,201,634,290,619,317,530,385"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_1"
                        />
                        <area
                          target=""
                          alt="master toilet"
                          data-title={"Master Toilet\n 5' x 8'"}
                          href=""
                          data-coords="420,395,489,345,523,386,453,439"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_2"
                        />
                        <area
                          target=""
                          alt="master wardrobe"
                          data-title={"Master Wardrobe\n 6'3\" x 2'"}
                          href=""
                          data-coords="428,301,446,290,491,341,472,355"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_3"
                        />
                        <area
                          target=""
                          alt="common toilet"
                          data-title={"Common Toilet\n 5' x 8'"}
                          href=""
                          data-coords="343,298,409,253,442,291,375,340"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_4"
                        />
                        <area
                          target=""
                          alt="master passage"
                          data-title={"Passage\n 5' x 3'5\""}
                          href=""
                          data-coords="411,252,439,233,473,271,443,289"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_5"
                        />
                        <area
                          target=""
                          alt="dry balcony"
                          data-title={"Dry Balcony\n 3'3\" x 7'5\""}
                          href=""
                          data-coords="301,396,370,348,393,380,323,428"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_6"
                        />
                        <area
                          target=""
                          alt="kitchen"
                          data-title={"Kitchen\n 10'6\"  x 8'4\""}
                          href=""
                          data-coords="233,307,304,258,370,342,297,397"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_7"
                        />
                        <area
                          target=""
                          alt="bedroom 2"
                          data-title={"Bedroom 2\n 10' x 12'"}
                          href=""
                          data-coords="412,196,468,162,466,131,500,113,569,178,541,197,537,224,473,269"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_8"
                        />
                        <area
                          target=""
                          alt="deck"
                          data-title={"Deck\n 11'2\" x 4'7\""}
                          href=""
                          data-coords="398,57,428,35,497,101,465,122"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_9"
                        />
                        <area
                          target=""
                          alt="living / dining"
                          data-title={
                            "Living/Dining \n 11'2\" x 19'4\" & 6'3\" x 11'8\""
                          }
                          href=""
                          data-coords="230,152,241,183,339,298,436,232,401,190,458,155,459,121,394,56"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_10"
                        />
                      </map>
                    </>
                  )}
                  {unit === "4" && (
                    <>
                      <img
                        src={unit4Typical}
                        useMap="#image-map"
                        ref={imgRef}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "100%",
                        }}
                      />
                      <map name="image-map">
                        <area
                          target=""
                          alt="master bedroom"
                          data-title={"Master Bedroom\n 12'6\" x 11'10\""}
                          href=""
                          data-coords="533,202,543,179,645,236,634,259,583,341,477,281"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_1"
                        />
                        <area
                          target=""
                          alt="master toilet"
                          data-title={"Master Toilet\n 8'8\" x 5'"}
                          href=""
                          data-coords="501,353,527,315,602,360,577,400"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_2"
                        />
                        <area
                          target=""
                          alt="master passage"
                          data-title={" Passage\n 12'2\" x 3'5\""}
                          href=""
                          data-coords="382,231,401,206,489,256,472,281"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_3"
                        />
                        <area
                          target=""
                          alt="bedroom 2 bathroom"
                          data-title={"Toilet 2\n 5' x 7'5\""}
                          href=""
                          data-coords="462,418,500,357,543,384,503,445"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_4"
                        />
                        <area
                          target=""
                          alt="bedroom 2"
                          data-title={" Bedroom 2 \n 10'10\" x 12'10\""}
                          href=""
                          data-coords="370,357,436,264,523,314,457,414"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_5"
                        />
                        <area
                          target=""
                          alt="bedroom 3"
                          data-title={" Bedroom 3 \n 10'8\" x 12'10\""}
                          href=""
                          data-coords="281,300,346,213,427,258,360,349"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_6"
                        />
                        <area
                          target=""
                          alt="common toilet"
                          data-title={"Common Toilet\n 5' x 8'"}
                          href=""
                          data-coords="401,203,442,152,480,172,442,225"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_7"
                        />
                        <area
                          target=""
                          alt="dry balcony"
                          data-title={"Dry Balcony \n 3'3\" x 8'4\""}
                          href=""
                          data-coords="497,180,523,142,530,115,541,103,568,116,557,150,530,197"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_8"
                        />
                        <area
                          target=""
                          alt="kitchen"
                          data-title={"Kitchen \n 10'6\" x 8'4\""}
                          href=""
                          data-coords="416,140,496,178,522,140,530,110,539,101,457,59,413,109"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_9"
                        />
                        <area
                          target=""
                          alt="deck"
                          data-title={"Deck\n 11'2\" x 4'7\""}
                          href=""
                          data-coords="172,214,201,183,218,210,283,250,290,261,266,290,262,299,197,259"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_10"
                        />
                        <area
                          target=""
                          alt="living / dining"
                          data-title={
                            "Living/Dining\n 11'2\" x 19'14\" & 5'5\" x 11'10\""
                          }
                          href=""
                          data-coords="297,256,337,202,379,227,436,151,415,140,411,110,317,55,205,179,211,186,222,210"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_4_11"
                        />
                      </map>
                    </>
                  )}
                </>
              )}
              {plan === "second" && (
                <>
                  {unit === "1" && (
                    <>
                      <img
                        src={unit1Typical}
                        useMap="#image-map"
                        ref={imgRef}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "100%",
                        }}
                      />
                      <map name="image-map">
                        <area
                          target=""
                          alt="master bedroom"
                          data-title={"Master Bedroom\n 11' x 12'10\""}
                          href=""
                          data-coords="507,118,508,93,607,50,668,150,656,177,562,218"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_1"
                        />
                        <area
                          target=""
                          alt="master passage"
                          data-title={"Master Passage\n 3'5\" x 3'5\""}
                          href=""
                          data-coords="531,230,515,201,545,189,561,217"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_2"
                        />
                        <area
                          target=""
                          alt="master wardrobe"
                          data-title={"Master Wardrobe\n 5' x 2'"}
                          href=""
                          data-coords="566,215,614,195,622,209,576,230"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_3"
                        />
                        <area
                          target=""
                          alt="master toilet"
                          data-title={"Master Toilet\n 5' x 8'4\""}
                          href=""
                          data-coords="615,195,656,177,668,152,696,199,705,195,724,225,707,244,659,265"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_4"
                        />
                        <area
                          target=""
                          alt="common Toilet"
                          data-title={"Common Toilet\n 8' x 4'5\""}
                          href=""
                          data-coords="485,253,554,223,573,261,507,292"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_5"
                        />
                        <area
                          target=""
                          alt="utility/storage"
                          data-title={"Utility/Storage\n 7' x 3'5\""}
                          href=""
                          data-coords="518,291,581,261,599,292,535,322"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_6"
                        />
                        <area
                          target=""
                          alt="dry balcony"
                          data-title={"Dry Balcony\n 3'3\" x 8'0\""}
                          href=""
                          data-coords="533,325,560,311,602,386,573,401"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_7"
                        />
                        <area
                          target=""
                          alt="pooja room"
                          data-title={"Pooja Room\n 1'2\" x 3'5\""}
                          href=""
                          data-coords="505,296,519,289,534,322,522,329"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_8"
                        />
                        <area
                          target=""
                          alt="passage 1"
                          data-title={"Passage 1\n 5' x 3'5\""}
                          href=""
                          data-coords="377,395,423,374,453,434,407,457"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_9"
                        />
                        <area
                          target=""
                          alt="kitchen"
                          data-title={"Kitchen\n 10'10\" x 8'"}
                          href=""
                          data-coords="465,455,426,374,530,328,572,399"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_10"
                        />
                        <area
                          target=""
                          alt="passage 2"
                          data-title={"Entrance Foyer\n 4'6\" x 6'3\""}
                          href=""
                          data-coords="468,223,515,202,530,230,482,252"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_11"
                        />
                        <area
                          target=""
                          alt="bedroom 3"
                          data-title={"Bedroom 3\n 11' x 12'"}
                          href=""
                          data-coords="385,116,400,147,404,173,432,235,534,188,496,120,499,94,485,71"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_12"
                        />
                        <area
                          target=""
                          alt="deck"
                          data-title={"Deck\n 11'2\" x 4'7\""}
                          href=""
                          data-coords="518,100,628,142,611,182,500,137"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_13"
                        />
                        <area
                          target=""
                          alt="Living / Dining"
                          data-title={
                            "Living / Dining\n 11'4\" x 19'10\" & 3'8\" x 12'2\""
                          }
                          href=""
                          data-coords="290,195,298,216,378,394,519,329,466,223,434,235,401,173,396,150"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_14"
                        />
                        <area
                          target=""
                          alt="Bedroom 2"
                          data-title={"Bedroom 2\n 11'7\" x 11'"}
                          href=""
                          data-coords="163,247,176,268,214,361,327,311,287,224,278,200"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_15"
                        />
                        <area
                          target=""
                          alt="Bedroom 2 Passage"
                          data-title={"Bedroom 2 Passage\n 3'5\" x 3'7\""}
                          href=""
                          data-coords="297,332,339,313,351,341,309,359"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_16"
                        />
                        <area
                          target=""
                          alt="Bedroom 2 Wardrobe"
                          data-title={"Bedroom 2 Wardrobe\n 2'10\" x 1'18\""}
                          href=""
                          data-coords="309,360,337,348,346,362,316,376"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_17"
                        />
                        <area
                          target=""
                          alt="Bedroom 2 Toilet"
                          data-title={"Toilet 2 \n 8' x 5'"}
                          href=""
                          data-coords="214,365,234,411,314,376,294,330"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_1_18"
                        />
                      </map>
                    </>
                  )}
                  {unit === "2" && (
                    <>
                      <img
                        src={unit2Typical}
                        useMap="#image-map"
                        ref={imgRef}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "100%",
                        }}
                      />
                      <map name="image-map">
                        <area
                          target=""
                          alt="master bedroom"
                          data-title={"Master Bedroom\n 11' x 12'10\""}
                          href=""
                          data-coords="545,167,631,132,640,94,713,181,699,188,690,221,608,259"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_1"
                        />
                        <area
                          target=""
                          alt="master passage"
                          data-title={"Master Passage\n 3'5\" x 3'5\""}
                          href=""
                          data-coords="512,182,543,169,558,192,528,204"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_2"
                        />
                        <area
                          target=""
                          alt="master toilet"
                          data-title={"Master Toilet\n 5' x 8'4\""}
                          href=""
                          data-coords="561,109,562,87,554,77,556,58,600,40,618,58,612,61,638,93,631,130,591,146"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_3"
                        />
                        <area
                          target=""
                          alt="master wardrobe"
                          data-title={"Master Wardrobe\n 5' x 2'"}
                          href=""
                          data-coords="537,153,579,135,589,147,546,166"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_4"
                        />
                        <area
                          target=""
                          alt="common bathroom"
                          data-title={"Common Toilet\n 8' x 4'5\""}
                          href=""
                          data-coords="444,166,512,139,531,170,465,197"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_5"
                        />
                        <area
                          target=""
                          alt="passage 1"
                          data-title={"Passage 1\n 5' x 3'5\""}
                          href=""
                          data-coords="469,198,511,181,527,206,481,224"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_6"
                        />
                        <area
                          target=""
                          alt="bedroom 3"
                          data-title={"Bedroom 3\n 11' x 12'"}
                          href=""
                          data-coords="450,238,552,198,617,289,512,330"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_7"
                        />
                        <area
                          target=""
                          alt="utility / storage"
                          data-title={"Utility/Storage\n 7' x 3'5\""}
                          href=""
                          data-coords="438,137,497,112,515,136,454,160"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_8"
                        />
                        <area
                          target=""
                          alt="dry balcony"
                          data-title={"Dry Balcony\n 3'3\" x 8'0\""}
                          href=""
                          data-coords="404,79,402,63,401,49,426,40,428,81,458,123,438,132"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_9"
                        />
                        <area
                          target=""
                          alt="kitchen"
                          data-title={"Kitchen\n 10'10\" x 8'"}
                          href=""
                          data-coords="309,123,306,104,294,86,396,51,401,78,436,134,333,173"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_10"
                        />
                        <area
                          target=""
                          alt="passage 2"
                          data-title={"Entrance Foyer\n 4'6\" x 6'3\""}
                          href=""
                          data-coords="258,116,298,102,335,171,295,185"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_11"
                        />
                        <area
                          target=""
                          alt="bedroom 2"
                          data-title={"Bedroom 2\n 11'7\" x 11'"}
                          href=""
                          data-coords="195,307,312,264,360,356,243,406"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_12"
                        />
                        <area
                          target=""
                          alt="bedroom 2 toilet"
                          data-title={"Toilet 2 \n 8' x 5'"}
                          href=""
                          data-coords="167,237,195,306,275,273,263,253,258,223,247,203"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_13"
                        />
                        <area
                          target=""
                          alt="bedroom 2 wardrobe"
                          data-title={"Bedroom 2 Wardrobe\n 2'10\" x 1'18\""}
                          href=""
                          data-coords="263,245,287,235,294,248,268,259"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_14"
                        />
                        <area
                          target=""
                          alt="bedroom 2 passage"
                          data-title={"Bedroom 2 Passage\n 3'5\" x 3'7\""}
                          href=""
                          data-coords="267,258,309,241,320,258,278,277"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_15"
                        />
                        <area
                          target=""
                          alt="deck"
                          data-title={"Deck\n 11'2\" x 4'7\""}
                          href=""
                          data-coords="373,345,484,295,504,323,503,353,398,398,394,388"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_16"
                        />
                        <area
                          target=""
                          alt="Living / Dining"
                          data-title={
                            "Living / Dining\n 11'4\" x 19'10\" & 3'8\" x 12'2\""
                          }
                          href=""
                          data-coords="290,190,426,140,480,224,447,239,482,292,371,343"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_2_17"
                        />
                      </map>
                    </>
                  )}
                  {unit === "3" && (
                    <>
                      <img
                        src={unit3Typical}
                        useMap="#image-map"
                        ref={imgRef}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "100%",
                        }}
                      />
                      <map name="image-map">
                        <area
                          target=""
                          alt="master bedroom"
                          data-title={"Master Bedroom\n 11' x 12'"}
                          href=""
                          data-coords="446,289,537,226,545,201,634,290,619,317,530,385"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_1"
                        />
                        <area
                          target=""
                          alt="master toilet"
                          data-title={"Master Toilet\n 5' x 8'"}
                          href=""
                          data-coords="420,395,489,345,523,386,453,439"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_2"
                        />
                        <area
                          target=""
                          alt="master wardrobe"
                          data-title={"Master Wardrobe\n 6'3\" x 2'"}
                          href=""
                          data-coords="428,301,446,290,491,341,472,355"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_3"
                        />
                        <area
                          target=""
                          alt="common toilet"
                          data-title={"Common Toilet\n 5' x 8'"}
                          href=""
                          data-coords="343,298,409,253,442,291,375,340"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_4"
                        />
                        <area
                          target=""
                          alt="master passage"
                          data-title={"Passage\n 5' x 3'5\""}
                          href=""
                          data-coords="411,252,439,233,473,271,443,289"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_5"
                        />
                        <area
                          target=""
                          alt="dry balcony"
                          data-title={"Dry Balcony\n 3'3\" x 7'5\""}
                          href=""
                          data-coords="301,396,370,348,393,380,323,428"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_6"
                        />
                        <area
                          target=""
                          alt="kitchen"
                          data-title={"Kitchen\n 10'6\"  x 8'4\""}
                          href=""
                          data-coords="233,307,304,258,370,342,297,397"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_7"
                        />
                        <area
                          target=""
                          alt="bedroom 2"
                          data-title={"Bedroom 2\n 10' x 12'"}
                          href=""
                          data-coords="412,196,468,162,466,131,500,113,569,178,541,197,537,224,473,269"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_8"
                        />
                        <area
                          target=""
                          alt="deck"
                          data-title={"Deck\n 11'2\" x 4'7\""}
                          href=""
                          data-coords="398,57,428,35,497,101,465,122"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_9"
                        />
                        <area
                          target=""
                          alt="living / dining"
                          data-title={
                            "Living/Dining \n 11'2\" x 19'4\" & 6'3\" x 11'8\""
                          }
                          href=""
                          data-coords="230,152,241,183,339,298,436,232,401,190,458,155,459,121,394,56"
                          shape="poly"
                          onClick={isTouchDevice ? handleAreaClick : undefined}
                          onMouseLeave={
                            !isTouchDevice ? handleAreaMouseLeave : undefined
                          }
                          onMouseEnter={
                            !isTouchDevice ? handleAreaMouseEnter : undefined
                          }
                          id="mainImage_3_10"
                        />
                      </map>
                    </>
                  )}
                </>
              )}
              {activeArea && (
                <svg
                  className="absolute pointer-events-none"
                  xmlns="http://www.w3.org/2000/svg"
                  width={imageSize.width}
                  height={imageSize.height}
                  style={{
                    top: `${imageSize.top}px`,
                    left: `${imageSize.left}px`,
                  }}
                >
                  <polygon
                    className="highlight"
                    points={activeArea.join(",")}
                  />

                  {(() => {
                    // Split text into multiple lines with maximum 15 characters per line
                    const lines = activeAreaName.split(" ").reduce(
                      (acc, word) => {
                        const lastLine = acc[acc.length - 1] || "";
                        if ((lastLine + " " + word).length <= 25) {
                          acc[acc.length - 1] = lastLine
                            ? `${lastLine} ${word}`
                            : word;
                        } else {
                          acc.push(word);
                        }
                        return acc;
                      },
                      [""]
                    );

                    // Calculate width and height for the rect based on longest line and number of lines
                    const maxLineWidth = Math.max(
                      ...lines.map((line) => line.length * 9)
                    );
                    const rectWidth = maxLineWidth + paddingX * 2;
                    const rectHeight = lines.length * 20 + paddingY * 2;

                    // X and Y coordinates for positioning the rect
                    const rectX =
                      Math.max(
                        ...activeArea.filter((_, index) => index % 2 === 0)
                      ) + 5;
                    const rectY =
                      Math.min(
                        ...activeArea.filter((_, index) => index % 2 !== 0)
                      ) -
                      25 -
                      paddingY;

                    return (
                      <>
                        {/* Background rect with calculated dimensions */}
                        <rect
                          x={rectX}
                          y={rectY}
                          width={rectWidth}
                          height={rectHeight}
                          fill="#d8b387"
                          rx="5"
                          ry="5"
                        />

                        {/* Render each line of text with calculated positioning */}
                        {lines.map((line, index) => (
                          <text
                            key={index}
                            x={rectX + paddingX}
                            y={rectY + paddingY + 16 + index * 16}
                            fill="white"
                            fontSize="14"
                            fontFamily="Outfit, sans-serif"
                            textAnchor="start"
                          >
                            {line}
                          </text>
                        ))}
                      </>
                    );
                  })()}
                </svg>
              )}
            </div>
            <div
              className="w-full xl:w-2/12  lg:w-2/12 md:w-12/12 w-full rounded-lg mt-6 xl:mt-0 lg:mt-0 "
            // style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}
            >
              <div
                className="flex xl:flex-wrap rounded-lg border lg:flex-wrap xl:gap-0 lg:gap-0 gap-4 items-center justify-between xl:py-6 py-6 xl:px-0 px-2"
                style={{
                  background: "#f0eeee",
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                }}
              >
                <div className="xl:pt-4 w-full xl:w-9/12 mx-auto">
                  <div
                    className="cursor-pointer font text-sm font-semibold text-center text-white py-2 rounded bgbutton"
                    onClick={() => history.goBack()}
                  >
                    Go Back
                  </div>
                </div>
                <div className="xl:pt-2 lg:pt-2 w-full xl:w-9/12 mx-auto">
                  <a href={`/2d-unit-b/${floor}/${plan}/${unit}`}>
                    <div className="font text-sm font-semibold text-center text-white py-2 rounded bgbutton">
                      2D Unit Plan
                    </div>
                  </a>
                </div>
                <div className="xl:pt-2 pt-2 xl:block lg:block block w-full xl:w-9/12 mx-auto">
                  {/* <div
                    className="cursor-pointer  font text-sm font-semibold text-center text-white py-2 rounded bgbutton"
                     // Pass the selected plan
                  >
                    Zoom Image
                  </div> */}
                </div>
              </div>

            </div>
          </div>
          {isZoomed && (
            <div className="zoom-overlay">
              <div className="zoom-container">
                {/* Image to be zoomed */}
                <img
                  src={imageToDisplay} // Dynamically set the zoomed image
                  alt={`${plan} plan`}
                  className="zoomed-image"
                />

                {/* Close button */}
                <div
                  className="close-button "
                  onClick={closeZoom}
                >
                  <i className="fas fa-times pl-1 "></i>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>

      <Footer />
    </>
  );
}
